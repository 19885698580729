import React from 'react'

export default function Headings(props) {
  return (
    <div className='w-full h-12 flex'>
        <div className='flex flex-col gap-1 px-8'>
            <p className='text-2xl font-custom10'>{props.heading}</p>
            <div className='w-auto ml-6 h-1 bg-black rounded-full'></div>
        </div>
    </div>
  )
}
