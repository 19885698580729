import React, { useContext } from "react";
import Headings from "../Components/Headers/Headings";
import ItemTile from "../Components/Tiles/ItemTile";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import myContext from "../Context/myContext";
import Loader from "../Components/Loader/loader";

export default function ProductListPage() {
  const navigate = useNavigate();
  const { categoryname } = useParams();
  const context = useContext(myContext);
  const { getAllProduct, loading } = context;

  const filterProduct = getAllProduct.filter(
    (obj) =>
      obj.categories.includes(categoryname) || obj.type.includes(categoryname)
  );

  return (
    <>
      <Navbar />
      <div className="my-14">
        <div className="w-full h-4"></div>
        <Headings
          heading={
            categoryname.slice(0, 1).toUpperCase() + categoryname.slice(1)
          }
        />
        <div className="w-full px-6 py-2 my-1">
          <div className="flex items-center flex-wrap justify-center gap-20 mobile:gap-6 mobile:px-3 notepad:gap-10 notepad:px-4 tablet:gap-6 tablet:px-5 px-8">
            {loading ? (
              <Loader />
            ) : (
              <>
                {filterProduct.length > 0 ? (
                  <>
                    {filterProduct.map((item, index) => {
                      return <ItemTile key={index} item={item} />;
                    })}
                  </>
                ) : (
                  <div>No Products</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="my-10"></div>
      </div>
      <Footer />
    </>
  );
}
