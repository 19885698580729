import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Headings from "../Components/Headers/Headings";
import FAQs from "../Components/Content/FAQs";
import Navbar from "../Components/Navbar/Navbar";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";

export default function FAQsPage() {
  const [getFAQS, setGetFAQS] = useState([]);

  const getFAQSFunction = async () => {
    try {
      const q = query(
        collection(fireDB, "FaQs"),
        orderBy("time", "desc"),
        limit(1)
      );
      const data = onSnapshot(q, (QuerySnapshot) => {
        let detailsFaqsArray = [];
        QuerySnapshot.forEach((doc) => {
          detailsFaqsArray.push({ ...doc.data(), id: doc.id });
        });
        setGetFAQS(detailsFaqsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFAQSFunction();
  }, []);

  return (
    <>
      <Navbar />
      <div className="mt-14 mb-32">
        <div className="w-full h-6"></div>
        <Headings heading={"FAQs"} />
        {getFAQS.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full font-custom12 py-4 flex items-center justify-center"
            >
              <div className="w-11/12 rounded-md notepad:px-1 notepad:text-justify px-4 py-8 shadow-light-shadow bg-red-50 flex flex-col gap-10">
                {item.FAQs.map((faqs, ind) => {
                  return (
                    <div
                      key={ind}
                      className="w-full px-4 flex flex-col gap-1 items-start justify-start"
                    >
                      <span className="font-semibold text-lg">
                        Q{ind + 1}. {faqs.question}
                      </span>
                      <p className="text-lg">
                        <span className="font-semibold mr-1">Ans:</span>
                        {faqs.response}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
}
