import React from "react";

export default function Highlights(props) {
  const highlights = props.product || [];
  return (
    <div className="w-full px-16 py-8 font-custom12 mobile:px-4 flex flex-col items-start justify-center gap-1">
      <p className="font-bold text-lg">Highlights</p>
      <ul className="list-disc pl-6">
        {highlights.map((highlight, index) => (
          <li key={index} className="mt-1">
            {highlight}
          </li>
        ))}
      </ul>
    </div>
  );
}
