import React, { useContext, useState } from "react";
import Carousel from "../Carousel/Carousel";
import { useNavigate } from "react-router-dom";
import myContext from "../../Context/myContext";
import { deleteDoc, doc } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import toast from "react-hot-toast";

export default function ProductDetailsAdmin({ product, id }) {
  const {
    title,
    description,
    origPrice,
    sellingPrice,
    size = [],
    imageUrls = [],
    highlights = [],
    categories = [],
    type = "",
    quantity,
  } = product;

  const context = useContext(myContext);
  const { setLoading, getAllProductFunction } = context;
  const navigate = useNavigate();

  // Delete product
  const deleteProduct = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(fireDB, "products", id));
      toast.success("Product Deleted successfully");
      getAllProductFunction();
      navigate("/admin/products");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col gap-1 font-custom12">
      <div className="w-full h-[32rem] notepad:h-auto flex notepad:flex-col notepad:items-center notepad:px-4 px-10 gap-3 my-2">
        <div className="h-full w-1/4 mobile:w-full notepad:w-1/2 notepad:items-center notepad:justify-center notepad:flex px-2 py-6">
          <Carousel imageUrls={imageUrls} />
        </div>
        <div className="h-full w-3/4 flex flex-col notepad:w-full justify-between px-2 pt-8 notepad:pt-2 notepad:gap-4 pb-6">
          <div className="w-full flex flex-col gap-2">
            <p className="text-xl font-bold">{title}</p>
            <p className="pl-2 text-lg">
              <span className="font-bold mr-2">Product ID:</span>
              <span className="font-normal">{id}</span>
            </p>
            <p className="text-base leading-tight tracking-normal text-justify px-2">
              {description}
            </p>
          </div>
          <div className="w-full flex items-center justify-between pr-14 notepad:flex-col notepad:items-start notepad:pr-2 notepad:gap-2">
            <p className="pl-3 text-lg">
              <span className="font-bold mr-2">Original Price:</span>
              <span className="font-normal">{origPrice} Rs.</span>
            </p>
            <p className="pl-3 text-lg">
              <span className="font-bold mr-2">Selling Price:</span>
              <span className="font-normal">{sellingPrice} Rs.</span>
            </p>
            <p className="pl-3 text-lg">
              <span className="font-bold mr-2">Maximum Qty:</span>
              <span className="font-normal">{quantity}</span>
            </p>
          </div>
          <div className="w-full h-14 flex items-center justify-between pr-14 notepad:pr-2 notepad:flex-col notepad:items-start notepad:gap-3 notepad:h-auto">
            <p className="pl-3 text-lg flex gap-1 items-center">
              <span className="font-bold mr-2">Sizes:</span>
              {size.map((size, index) => (
                <span
                  key={index}
                  className="font-normal border-[1.75px] border-black px-2"
                >
                  {size}
                </span>
              ))}
            </p>
            <p className="pl-3 text-lg">
              <span className="font-bold mr-2">Type:</span>
              <span className="font-normal">
                {type.slice(0, 1).toUpperCase()}
                {type.slice(1, 8)}
              </span>
            </p>
            <p className="pl-3 text-lg gap-1 flex">
              <span className="font-bold mr-2">Categories:</span>
              {categories.map((category, index) => (
                <span
                  key={index}
                  className="font-normal border-[1.75px] border-black px-2"
                >
                  {category.slice(0, 1).toUpperCase()}
                </span>
              ))}
            </p>
          </div>
          <div className="w-full flex items-center justify-between pr-14 notepad:pr-2 text-justify">
            <p className="pl-3 text-base">
              <span className="font-bold mr-2">Highlights:</span>
              <span className="font-normal text-base">
                {highlights.join(" ; ")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-16 mb-2 flex items-center justify-center gap-28 notepad:gap-4 notepad:justify-around notepad:px-4">
        <div
          onClick={() => navigate(`/admin/updateproduct/${id}`)}
          className="relative bg-transparent w-96 flex items-center shadow-md justify-center h-12 rounded-md border-2 border-btn-color-red cursor-pointer text-btn-color-red overflow-hidden group active:border-none"
        >
          <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
            Edit
          </span>
          <div className="absolute inset-0 bg-btn-color-red-hover transform translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
        </div>
        <div
          onClick={() => {
            deleteProduct(id);
          }}
          className="relative bg-btn-color-red-hover w-96 flex items-center shadow-md justify-center h-12 rounded-md cursor-pointer text-white overflow-hidden group active:border-none"
        >
          <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
            Delete
          </span>
          <div className="absolute inset-0 bg-btn-color-red transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
        </div>
      </div>
    </div>
  );
}
