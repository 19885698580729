import React, { useContext, useEffect, useState } from "react";
import Headers from "../Components/Headers/Headers";
import ProductDetailsAdmin from "../Components/Content/ProductDetailsAdmin";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";
import myContext from "../Context/myContext";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";
import Loader from "../Components/Loader/loader";

export default function AdminProductDetails() {
  const context = useContext(myContext);
  const { loading, setLoading } = context;

  const [product, setProduct] = useState("");

  const { id } = useParams();
  const getProductData = async () => {
    setLoading(true);
    try {
      const productTemp = await getDoc(doc(fireDB, "products", id));
      setProduct(productTemp.data());
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={product.title} />
      <div className="w-full h-4"></div>
      {loading && <div className="w-full flex justify-center"><Loader/></div>}
      {!loading && <ProductDetailsAdmin product={product} id={id} />}
    </>
  );
}
