import React from "react";
import { useNavigate } from "react-router-dom";

export default function Navigation(props) {
  const navigate = useNavigate();
  const val = props.gotohere;
  return (
    <div className="w-full h-[5rem] py-2 px-8 notepad:px-4 font-custom12">
      <div
        onClick={() => navigate(`/admin/${val}`)}
        className="flex items-center justify-between px-8 text-lg font-se w-full h-full bg-tile-color-2 rounded-xl cursor-pointer shadow-md hover:bg-gray-300 transition-all duration-200 ease-in-out"
      >
        <p>{props.title}</p>
        <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6 text-red-400"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
              clipRule="evenodd"
            />
          </svg>
        </p>
      </div>
    </div>
  );
}
