import React, { useContext } from "react";
import Headers from "../Components/Headers/Headers";
import ProductTileAdmin from "../Components/Tiles/ProductTileAdmin";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";
import myContext from "../Context/myContext";
import Loader from "../Components/Loader/loader";

export default function AdminViewProducts() {
  const context = useContext(myContext);
  const { loading, getAllProduct } = context;
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers />
      <div className="w-full h-4"></div>
      <div className="w-full px-6 py-2 my-1">
        <div className="flex items-center flex-wrap justify-center gap-20 px-8 notepad:px-4 notepad:gap-10">
          {loading && <Loader />}
          {getAllProduct.map((item, index) => {
            return (
              <div key={index}>
                <ProductTileAdmin item={item} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
