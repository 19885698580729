import React from "react";
import Headers from "../Components/Headers/Headers";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";
import UpdateProducts from "../Components/Forms/UpdateProducts";

export default function AdminUpdateProduct() {
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={"Edit Product Details"} />
      <UpdateProducts />
    </>
  );
}
