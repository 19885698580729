import React from "react";
import logo from "../../Assets/images/logo/Raajhans.svg";
import { useNavigate } from "react-router-dom";

export default function NavbarAdmin() {

  const navigate = useNavigate();

  const handleLogout= () => {
    localStorage.clear("user");
    navigate("/admin/login");
  }

  return (
    <nav className="fixed top-0 left-0 w-screen h-14 z-500">
      <div className="flex h-full w-full bg-nav-bg-active justify-between">
        <div className="h-full w-1/3 mobile:w-full flex items-center justify-start px-5 gap-3">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={logo} className="w-8 h-8" alt="RH" />
          </div>
          <div className="text-xl font-custom1 text-white mobile:w-full">Raajhans Admin</div>
        </div>
        <div className="h-full w-1/4 mobile:grow mobile:px-2 flex items-center justify-end px-12 gap-4">
          <div onClick={handleLogout} className="relative w-20 flex items-center justify-center h-10 rounded-md mobile:border-[1.5px] font-custom12 notepad:text-sm border-2 border-white cursor-pointer text-white overflow-hidden group active:border-none">
            <span className="relative z-10 transition-colors duration-500 group-hover:text-red-800">
              Logout
            </span>
            <div className="absolute inset-0 bg-white transform -translate-x-full transition-transform duration-200 group-hover:translate-x-0 group-active:bg-gray-400"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}
