import React, { useContext, useEffect, useState } from "react";
import Headers from "../Components/Headers/Headers";
import Headings from "../Components/Headers/Headings";
import Queries from "../Components/Content/Queries";
import SavedEmail from "../Components/Content/SavedEmail";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";
import myContext from "../Context/myContext";
import Loader from "../Components/Loader/loader";

export default function AdminQueries() {
  const context = useContext(myContext);
  const { loading, setLoading } = context;
  const [getAllQueries, setGetAllQueries] = useState([]);
  const [getAllEmails, setGetAllEmails] = useState([]);

  const getAllQueriesFunction = async () => {
    setLoading(true);
    try {
      const q = query(collection(fireDB, "queries"), orderBy("time", "desc"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let queryArray = [];
        QuerySnapshot.forEach((doc) => {
          queryArray.push({ ...doc.data(), id: doc.id });
        });
        setGetAllQueries(queryArray);
        setLoading(false);
      });
      return () => data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAllEmailsFunction = async () => {
    setLoading(true);
    try {
      const q = query(collection(fireDB, "userEmails"), orderBy("time", "desc"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let emailsArray = [];
        QuerySnapshot.forEach((doc) => {
          emailsArray.push({ ...doc.data(), id: doc.id });
        });
        setGetAllEmails(emailsArray);
        setLoading(false);
      });
      return () => data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllQueriesFunction();
    getAllEmailsFunction();
  }, []);

  return (
    <div>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={"Customer Queries and Emails"} />
      <div className="w-full h-4"></div>
      <Headings heading={"Queries"} />
      <div className="w-full flex flex-col gap-3 py-1 justify-center">
        {loading && <Loader />}
        {getAllQueries.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full py-4 flex items-center justify-center font-custom12 text-justify"
            >
              <div className="w-11/12 rounded-md px-4 py-5 shadow-light-shadow bg-tile-color-2 flex flex-col gap-4">
                <div className="w-full px-4 flex gap-1 notepad:gap-3 items-center justify-center notepad:flex-col notepad:items-start">
                  <p className="w-1/2 notepad:w-full text-lg">
                    <span className="font-semibold mr-2">Customer Name:</span>
                    {item.yourName}
                  </p>
                  <p className="w-1/2 notepad:w-full text-lg">
                    <span className="font-semibold mr-2">Customer Email:</span>
                    {item.yourEmail}
                  </p>
                </div>
                <div className="w-full px-4 flex gap-1 items-center justify-start">
                  <p className="text-lg">
                    <span className="font-semibold mr-2">Message Subject:</span>
                    {item.yourSubject}
                  </p>
                </div>
                <div className="w-full px-4 flex gap-1 items-center justify-start">
                  <p className="text-lg">
                    <span className="font-semibold mr-2">Message Body:</span>
                    {item.yourMessage}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full h-8"></div>
      <Headings heading={"Emails"} />
      <div className="w-full flex flex-col justify-center gap-3 py-1">
        {loading && <Loader />}
        {getAllEmails.map((item, index) => {
          return (
            <div
              key={index}
              className="w-1/2 py-4 px-8 notepad:px-4 text-justify flex items-center justify-start notepad:font-custom12 notepad:w-full"
            >
              <div className="w-4/5 rounded-md px-4 py-5 notepad:px-2 shadow-light-shadow bg-tile-color-2 flex flex-col gap-4 notepad:w-full">
                <div className="w-full px-4 flex gap-1 items-center justify-start">
                  <p className="text-lg notepad:text-md">
                    <span className="font-semibold mr-2">
                      Email-{`${index + 1}`}:
                    </span>
                    {item.userEmailId}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
