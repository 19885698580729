import React, { useContext, useEffect, useState } from "react";
import Headers from "../Components/Headers/Headers";
import Highlights from "../Components/Content/Highlights";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import myContext from "../Context/myContext";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "../Components/Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  deleteFromCart,
  incrementQuantity,
} from "../redux/cartSlice";
import toast from "react-hot-toast";

export default function ProductDetailsPage() {
  const cartItems = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const deleteCart = (item) => {
    dispatch(deleteFromCart(item));
    toast.success("Deleted from cart!");
  };

  const [counter, setCounter] = useState(1);
  const [product, setProduct] = useState("");
  const allSizes = ["S", "M", "L", "XL", "XXL"];
  const navigate = useNavigate();
  const context = useContext(myContext);
  const { loading, setLoading } = context;
  
  const imageUrls = product.imageUrls || [];
  const availSizes = product.size || [];
  const initialSize = availSizes.length > 0 ? availSizes[0] : "M";
  const [itemSize, setItemSize] = useState(initialSize);
  const discount = Math.round(
    ((product.origPrice - product.sellingPrice) / product.origPrice) * 100
  );

  const handleIncrement = () => {
    dispatch(deleteFromCart(product));
    if (counter < product.quantity) {
      setCounter(counter + 1);
    }
  };

  const handleDecrement = () => {
    dispatch(deleteFromCart(product));
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    dispatch(deleteFromCart(product));
    if (isNaN(value)) {
      setCounter(1);
    } else if (value < 1) {
      setCounter(1);
    } else if (value > product.quantity) {
      setCounter(product.quantity);
    } else {
      setCounter(value);
    }
  };

  useEffect(() => {
    if (!availSizes.includes(itemSize)) {
      setItemSize(availSizes[0] || "M");
    }
  }, [availSizes, itemSize]);

  const { id } = useParams();
  const getProductData = async () => {
    setLoading(true);
    try {
      const productTemp = await getDoc(doc(fireDB, "products", id));
      setProduct({ ...productTemp.data(), id: productTemp.id });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    getProductData();
  }, []);

  const addCart = (item) => {
    // console.log(item)
    const itemsToPush = JSON.parse(JSON.stringify(item));
    itemsToPush.size = [itemSize];
    itemsToPush.quantity = counter;
    dispatch(addToCart(itemsToPush));
    toast.success("Added to cart");
  };

  return (
    <>
      <Navbar />
      <div className="my-14">
        <div className="w-full h-8"></div>
        <Headers heading={`Details: ${product?.title || 'Please Wait'}`} />
        <div className="w-full h-[32rem] mobile:h-auto flex mobile:flex-col mobile:px-4 px-10 gap-3 my-2">
          <div className="h-full w-1/4 mobile:w-full notepad:w-1/3 px-2 py-6">
            <Carousel imageUrls={imageUrls} />
          </div>
          <div className="h-full w-3/4 mobile:w-full flex flex-col justify-evenly px-2 mobile:px-0 mobile:py-6 mobile:justify-center mobile:gap-3 py-10 font-custom12">
            <div className="w-full">
              <p className="text-3xl font-bold">{product?.title || 'Please Wait'}</p>
              <p className="text-base leading-tight tracking-normal text-justify py-2 px-2">
                {product?.description || 'Please Wait'}
              </p>
            </div>
            <div className="w-full h-14 flex items-center">
              <p className="pl-3 text-xl font-bold text-text-color-green">
                {product?.sellingPrice || 'Please Wait'} Rs.
              </p>
              <p className="pl-5 text-lg font-semibold text-text-color-gray line-through">
                {product?.origPrice || 'Please Wait'} Rs.
              </p>
              <p className="pl-3 text-text-color-red">{discount}% off</p>
            </div>
            <div className="w-full h-20 flex flex-col gap-1 justify-around">
              <p className="text-xl font-bold">
                Size
                <span className="hidden text-base font-normal underline underline-offset-4 pl-2 cursor-pointer">
                  (Size Chart)
                </span>
              </p>
              <div className="flex px-5 gap-24 mobile:gap-0 mobile:items-center mobile:justify-between">
                {allSizes
                  .filter((size) => availSizes.includes(size))
                  .map((size) => (
                    <div
                      key={size}
                      className="flex items-center gap-3 mobile:gap-1 justify-center"
                    >
                      <div className="w-[1.15rem] h-[1.15rem] rounded-full border-2 border-black flex items-center justify-center">
                        <button
                          onClick={() => {setItemSize(size); dispatch(deleteFromCart(product));}}
                          className={`w-[0.75rem] h-[0.75rem] rounded-full transition-all duration-150 ease-in-out cursor-pointer ${
                            itemSize === size
                              ? "bg-red-500"
                              : "hover:bg-gray-400"
                          }`}
                        ></button>
                      </div>
                      <p className="font-semibold">{size}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-full h-20 flex mobile:mt-4 flex-col gap-1 justify-around">
              <p className="text-xl font-bold">Quantity</p>
              <div className="flex items-center bg-white w-28 justify-between rounded-md shadow-sm border-[1.6px] border-gray-700 overflow-hidden ml-6">
                <button
                  onClick={handleDecrement}
                  className="text-2xl font-semibold px-2 hover:bg-red-200 active:bg-red-300 transition-all duration-0 pb-1"
                >
                  -
                </button>
                <input
                  type="text"
                  value={counter}
                  onChange={handleChange}
                  className="text-center outline-none w-16"
                />
                <button
                  onClick={handleIncrement}
                  className="text-2xl font-semibold px-1 hover:bg-red-200 active:bg-red-300 transition-all duration-0 pb-1"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-16 mb-2 flex items-center justify-center mobile:gap-3 mobile:justify-evenly mobile:px-4 gap-28">
          {cartItems.some((p) => p.id === product.id) ? (
            <div
              onClick={() => deleteCart(product)}
              className="relative bg-transparent w-96 flex items-center shadow-md justify-center h-12 rounded-md border-2 border-btn-color-red-hover cursor-pointer text-btn-color-red overflow-hidden group active:border-none"
            >
              <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
                Remove from Cart
              </span>
              <div className="absolute inset-0 bg-btn-color-red transform translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
            </div>
          ) : (
            <div
              onClick={() => addCart(product)}
              className="relative bg-transparent w-96 flex items-center shadow-md justify-center h-12 rounded-md border-2 border-btn-color-red-hover cursor-pointer text-btn-color-red overflow-hidden group active:border-none"
            >
              <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
                Add to Cart
              </span>
              <div className="absolute inset-0 bg-btn-color-red transform translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
            </div>
          )}

          <div onClick={() => navigate("/cart")} className="relative bg-btn-color-red-hover w-96 flex items-center shadow-md justify-center h-12 rounded-md cursor-pointer text-white overflow-hidden group active:border-none">
            <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
              Go to Cart
            </span>
            <div className="absolute inset-0 bg-btn-color-red transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
          </div>
        </div>
        <Highlights product={product?.highlights || []} />
      </div>
      <Footer />
    </>
  );
}
