import React from "react";
import Headers from "../Components/Headers/Headers";
import SiteInformation from "../Components/Forms/SiteInformation";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";

export default function AdminSiteInfo() {
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={"Update Site Details"} />
      <SiteInformation />
    </>
  );
}
