import React, { useEffect } from "react";
import Headers from "../Components/Headers/Headers";
import Navbar from "../Components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { deleteFromCart } from "../redux/cartSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function CartPage() {
  const cartItems = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCart = (item) => {
    dispatch(deleteFromCart(item));
    toast.success("Deleted from cart!");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);
  return (
    <>
      <Navbar />
      <div className="my-14">
        <div className="w-full h-4"></div>
        <Headers heading={"Your Cart Items"} />
        <div className="flex flex-col items-center justify-center gap-4 notepad:px-2 py-2 px-8">
          {cartItems.map((item, index) => {
            const {id, title, sellingPrice, imageUrls, quantity, size} = item;
            return (
              <div
                key={index}
                onClick={() => navigate(`/productinfo/${id}`)}
                className="relative h-20 font-custom12 mobile:h-full bg-tile-color hover:bg-gray-100 w-full rounded-lg my-2 flex p-2 shadow-md overflow-hidden cursor-pointer"
              >
                <div className="mobile:hidden w-full flex">
                  <div className="grow h-full flex pl-1 pr-3 overflow-hidden">
                    <div className="w-16 h-full rounded-lg overflow-hidden">
                      <img
                        src={imageUrls[0]}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </div>
                    <div className="h-full grow mx-2 flex items-center font-custom10 justify-start text-text-color-red">
                      {title}
                    </div>
                  </div>
                  <div className="w-1/4 h-full flex items-center justify-start gap-1">
                    <p className="font-custom10">Item Price:</p>
                    <p
                      className="font-custom7 text-text-color-red
                  "
                    >
                      {sellingPrice} Rs. x {quantity}
                    </p>
                  </div>
                  <div className="w-1/5 h-full flex items-center justify-start gap-1">
                    <p className="font-custom10">Size:</p>
                    <p
                      className="font-custom7 text-text-color-red
                  "
                    >
                      {size[0]}
                    </p>
                  </div>
                  <div className="w-1/5 h-full flex items-center justify-start gap-1">
                    <p className="font-custom10">Qty:</p>
                    <p
                      className="font-custom7 text-text-color-red
                  "
                    >
                      {quantity}
                    </p>
                  </div>
                  <div className="w-10 h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={(e) => {e.stopPropagation(); deleteCart(item)}}
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-8 rounded-full hover:bg-gray-300 cursor-pointer text-btn-color-red px-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mobile:flex hidden w-full justify-between">
                  <div className="grow h-full flex pl-1 pr-1 overflow-hidden">
                    <div className="w-16 h-full rounded-lg overflow-hidden">
                      <img
                        src={imageUrls[0]}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </div>
                    <div className="px-2 overflow-hidden flex flex-col text-sm">
                      <div className="font-custom10 h-full grow flex items-center justify-start text-text-color-red">
                        {title}
                      </div>
                      <div className="w-full h-full flex items-center justify-start gap-1">
                        <p className="font-custom10">Item Price:</p>
                        <p className="font-custom7 text-text-color-red">
                          {sellingPrice} Rs. x {quantity}
                        </p>
                      </div>
                      <div className="w-full h-full flex items-center justify-start gap-1">
                        <p className="font-custom10">Size:</p>
                        <p className="font-custom7 text-text-color-red">{size[0]}</p>
                      </div>
                      <div className="w-full h-full flex items-center justify-start gap-1">
                        <p className="font-custom10">Qty:</p>
                        <p className="font-custom7 text-text-color-red">{quantity}</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-10 h-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      onClick={(e) => {e.stopPropagation(); deleteCart(item)}}
                      fill="currentColor"
                      className="size-8 rounded-full active:bg-gray-200 cursor-pointer text-btn-color-red px-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {cartItems.length > 0 && (<div className="mt-3 px-10 flex items-center justify-end">
          <div onClick={() => navigate("/orderSummary")} className="relative bg-btn-color-red-hover w-96 flex items-center shadow-md justify-center h-12 rounded-md cursor-pointer text-white overflow-hidden group active:border-none">
            <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
              Order Now
            </span>
            <div className="absolute inset-0 bg-btn-color-red transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
          </div>
        </div>)}
      </div>
    </>
  );
}
