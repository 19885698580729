import React, { useContext } from "react";
import samplePic from "../../Assets/images/sample/RMP_8630_zoom.jpeg";
import { useNavigate } from "react-router-dom";
import myContext from "../../Context/myContext";
import { deleteDoc, doc } from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import toast from "react-hot-toast";

export default function ProductTileAdmin(props) {
  const context = useContext(myContext);
  const { setLoading, getAllProductFunction } = context;
  const { id, title, sellingPrice, quantity, size, imageUrls } = props.item;
  const navigate = useNavigate();

  // Delete product
  const deleteProduct = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(fireDB, "products", id));
      toast.success("Product Deleted successfully");
      getAllProductFunction();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div
      onClick={() => navigate(`/admin/productinfos/${id}`)}
      className="bg-gray-200 w-64 h-[30rem] notepad:h-auto font-custom12 rounded-xl flex flex-col m-3 notepad:m-2 shadow-light-shadow overflow-hidden cursor-pointer"
    >
      <div className="w-full grow rounded-t-xl overflow-hidden">
        <img src={imageUrls[0]} className="w-full h-full object-cover" alt="" />
      </div>
      <div className="w-full bg-white h-40 notepad:h-auto rounded-b-xl">
        <div className="w-full h-10 flex items-center px-2 py-1">
          <p className="w-full h-full overflow-hidden flex items-end text-red-700 whitespace-nowrap font-semibold">
            {title}
          </p>
        </div>
        <div className="w-full px-2 pt-1 pb-2 flex justify-between items-center h-28 notepad:h-auto">
          <div className="w-5/6 h-full flex flex-col items-start justify-between">
            <p className="px-1">
              <span className="font-semibold mr-1">Product ID:</span>
              {id.substring(0, 8)}...
            </p>
            <p className="px-1">
              <span className="font-semibold mr-1">Item Price:</span>
              {sellingPrice}
            </p>
            <p className="px-1">
              <span className="font-semibold mr-1">Max. Qty:</span>
              {quantity}
            </p>
            <p className="px-1">
              <span className="font-semibold mr-1">Size:</span>
              {size.join(", ")}
            </p>
          </div>
          <div className="w-1/6 h-full flex flex-col items-end justify-end gap-2">
            <div className="w-8 h-8 rounded-full text-white flex items-center justify-center hover:bg-gray-200 transition-all duration-300 ease-in-out active:bg-slate-300">
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/admin/updateproduct/${id}`);
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 text-btn-color-red"
              >
                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
              </svg>
            </div>
            <div className="w-8 h-8 rounded-full text-white flex items-center justify-center hover:bg-gray-200 transition-all duration-300 ease-in-out active:bg-slate-300">
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  deleteProduct(id);
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 text-btn-color-red"
              >
                <path
                  fillRule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
