import React, { useContext, useState } from "react";
import Headings from "../Components/Headers/Headings";
import OrderDetailsTile from "../Components/Content/OrderDetailsTile";
import Headers from "../Components/Headers/Headers";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";
import myContext from "../Context/myContext";
import OrderedProductTile from "../Components/Tiles/OrderedProductTile";
import Toggle from "../Components/Utilities/Toggle";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";

export default function AdminViewOrders() {
  const context = useContext(myContext);
  const { getAllOrder, deleteOrder } = context;

  const handleChange = async (status, currentStatus, orderId) => {
    let newStatus;
    if (currentStatus >= status) {
      newStatus = status - 1;
    } else {
      newStatus = status;
    }
    // Update Firestore with the new status
    try {
      // Check if the status change is valid and different
      if (currentStatus !== newStatus) {
        const orderRef = doc(fireDB, "order", orderId);
        await updateDoc(orderRef, { status: newStatus });
        console.log(`Order ${orderId} status updated to ${newStatus}`);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={"All Orders"} />
      <div className="w-full h-4"></div>
      <Headings heading={"Active Orders"} />
      <div className="w-full flex flex-col gap-3 py-1">
        {getAllOrder.map((order, index) => {
          return (
            <div
              key={index}
              className="w-full px-4 py-4 flex flex-col font-custom12 items-center justify-center gap-1 my-2"
            >
              <div className="w-full bg-tile-color-2 rounded-2xl shadow-light-shadow flex px-6 py-5 gap-5 flex-col">
                <div className="w-full h-48 flex items-center justify-between notepad:flex-col notepad:h-auto">
                  <div className="w-1/2 h-full flex notepad:w-full notepad:flex-col">
                    <div className="h-full grow flex">
                      <div className="h-full text-base notepad:gap-2 font-custom10 mr-1 flex flex-col items-start justify-between">
                        <p>
                          Customer Name:
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.addressInfo.firstName}{" "}
                            {order.addressInfo.lastName}
                          </span>
                        </p>
                        <p>
                          Phone:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.addressInfo.phoneNo}
                          </span>
                        </p>
                        <p>
                          Alternate Phone:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.addressInfo.altPhoneNo}
                          </span>
                        </p>
                        <p>
                          Email Id:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.addressInfo.emailId}
                          </span>
                        </p>
                        <p>
                          Order Date:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.addressInfo.date}
                          </span>
                        </p>
                        <p>
                          Total Amount:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                          {order?.totalAmount || 'Issue with payment. Please verify payment.'}
                          </span>
                        </p>
                        <p>
                          Payment Mode:{" "}
                          <span className="font-normal text-base text-justify font-custom7">
                            {order.paymentMode}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="h-full w-96 px-2 py-1 flex notepad:w-full notepad:px-0 notepad:pt-2">
                      <p className="h-full text-base font-bold mr-1">
                        Address:
                      </p>
                      <p className="text-base text-justify font-custom7">
                        {order.addressInfo.houseNo}
                        {", "}
                        {order.addressInfo.areaRoad}
                        {"("}
                        {order.addressInfo.landmark}
                        {"), "}
                        {order.addressInfo.city}
                        {", "}
                        {order.addressInfo.state}
                        {" ["}
                        {order.addressInfo.pincode}
                        {"]"}
                      </p>
                    </div>
                  </div>
                  <div className="w-1/4 notepad:w-full notepad:items-start h-full flex flex-col items-end justify-start gap-2 notepad:my-4">
                    <p className="text-base font-custom5">
                      Change Order Status
                    </p>
                    <div className="w-full h-full flex flex-col items-start justify-between">
                      <div className="flex w-full items-center space-x-4 justify-end notepad:justify-between font-custom7">
                        <span className="text-black text-base">
                          Order Placed
                        </span>
                        <label className="relative inline-flex items-center cursor-pointer px-2 py-2">
                          <input
                            type="checkbox"
                            checked={order.status >= 1}
                            onChange={null}
                            className="sr-only"
                          />
                          <div
                            className={`w-9 h-3 rounded-full shadow-inner ${
                              true ? "bg-red-400" : "bg-gray-400"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-0 w-5 h-5 rounded-full shadow transform transition-transform duration-300 ease-in-out ${
                              true
                                ? "translate-x-7 bg-red-600"
                                : "translate-x-[0.4rem] bg-white"
                            }`}
                          ></div>
                        </label>
                      </div>
                      <div className="flex w-full items-center space-x-4 justify-end notepad:justify-between font-custom7">
                        <span className="text-black text-base">
                          Order Confirmed
                        </span>
                        <label className="relative inline-flex items-center cursor-pointer px-2 py-2">
                          <input
                            type="checkbox"
                            checked={order.status >= 2}
                            onChange={() => {
                              handleChange(2, order.status, order.id);
                            }}
                            className="sr-only"
                          />
                          <div
                            className={`w-9 h-3 rounded-full shadow-inner ${
                              order.status >= 2 ? "bg-red-400" : "bg-gray-400"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-0 w-5 h-5 rounded-full shadow transform transition-transform duration-300 ease-in-out ${
                              order.status >= 2
                                ? "translate-x-7 bg-red-600"
                                : "translate-x-[0.4rem] bg-white"
                            }`}
                          ></div>
                        </label>
                      </div>
                      <div className="flex w-full items-center space-x-4 justify-end notepad:justify-between font-custom7">
                        <span className="text-black text-base">
                          Out For Delivery
                        </span>
                        <label className="relative inline-flex items-center cursor-pointer px-2 py-2">
                          <input
                            type="checkbox"
                            checked={order.status >= 3}
                            onChange={() => {
                              handleChange(3, order.status, order.id);
                            }}
                            className="sr-only"
                          />
                          <div
                            className={`w-9 h-3 rounded-full shadow-inner ${
                              order.status >= 3 ? "bg-red-400" : "bg-gray-400"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-0 w-5 h-5 rounded-full shadow transform transition-transform duration-300 ease-in-out ${
                              order.status >= 3
                                ? "translate-x-7 bg-red-600"
                                : "translate-x-[0.4rem] bg-white"
                            }`}
                          ></div>
                        </label>
                      </div>
                      <div className="flex w-full items-center space-x-4 justify-end notepad:justify-between font-custom7">
                        <span className="text-black text-base">Delivered</span>
                        <label className="relative inline-flex items-center cursor-pointer px-2 py-2">
                          <input
                            type="checkbox"
                            checked={order.status >= 4}
                            onChange={() => {
                              handleChange(4, order.status, order.id);
                            }}
                            className="sr-only"
                          />
                          <div
                            className={`w-9 h-3 rounded-full shadow-inner ${
                              order.status >= 4 ? "bg-red-400" : "bg-gray-400"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-0 w-5 h-5 rounded-full shadow transform transition-transform duration-300 ease-in-out ${
                              order.status >= 4
                                ? "translate-x-7 bg-red-600"
                                : "translate-x-[0.4rem] bg-white"
                            }`}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-48 h-full flex items-start justify-end notepad:justify-center notepad:my-3">
                    {order.status === 4 && (<button
                      onClick={() => {
                        deleteOrder(order.id);
                      }}
                      className="px-3 py-2 rounded-md hover:bg-btn-color-red-hover transition-all duration-200 ease-in-out text-sm font-light bg-btn-color-red active:to-btn-color-red-active text-white"
                    >
                      Delete Order
                    </button>)}
                  </div>
                </div>
                <div className="w-full flex flex-col items-center justify-center gap-4">
                  {/* <div className="w-full h-9 rounded-lg flex items-center justify-center text-white bg-red-500 hover:bg-red-700 active:bg-red-900 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div> */}
                  <div className="w-full flex items-center overflow-x-scroll">
                    {order.cartItems.map((item, index) => {
                      const {
                        id,
                        title,
                        size,
                        quantity,
                        sellingPrice,
                        imageUrls,
                      } = item;
                      return (
                        <div key={index} className="w-96 m-2">
                          <div className="bg-white w-96 overflow-hidden rounded-xl shadow-md flex pb-1 items-center justify-center flex-col">
                            <div className="flex cursor-pointer w-full justify-evenly hover:bg-gray-200 transition-all duration-200 ease-in-out">
                              <div className="flex pl-4 py-4 pr-1 grow h-48 gap-3">
                                <div className="h-full w-28 rounded-lg overflow-hidden">
                                  <img
                                    src={imageUrls[0]}
                                    className="w-full h-full object-cover"
                                    alt=""
                                  />
                                </div>
                                <div className="h-full grow font-bold flex flex-col text-text-color-red justify-evenly">
                                  <p className="text-base leading-tight">
                                    {title}
                                  </p>
                                  <p>
                                    <span className="font-normal text-black">
                                      Item Id:{" "}
                                    </span>
                                    {id.length > 10 ? id.slice(0, 10) : id}
                                  </p>
                                  <p>
                                    <span className="font-normal text-black">
                                      Size:{" "}
                                    </span>
                                    {size[0]}
                                  </p>
                                  <p>
                                    <span className="font-normal text-black">
                                      Qty:{" "}
                                    </span>
                                    {quantity}
                                  </p>
                                  <p>
                                    <span className="font-normal text-black">
                                      Total Price:{" "}
                                    </span>
                                    {quantity * sellingPrice} Rs.
                                  </p>
                                </div>
                              </div>
                              <div className="h-full w-8 flex items-start justify-center py-2">
                                <div className="w-8 h-8 text-red-500 cursor-pointer hidden">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-8 px-1 rounded-full hover:bg-gray-700 active:bg-slate-600"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div className="w-full pl-4 pr-8 pb-2 hidden">
                              <div className="w-full rounded-md">
                                <p className="text-base font-bold flex justify-end notepad:justify-start mb-1">
                                  Change Order Status
                                </p>
                                <div className="w-full h-full flex flex-col gap-1 items-start justify-between">
                                  {/* <Toggle />
                                  <Toggle />
                                  <Toggle />
                                  <Toggle /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="w-full h-8"></div>
      <Headings heading={"Past Orders"} />
      <div className="w-full flex flex-col gap-3 py-1">
        <OrderDetailsTile />
        <OrderDetailsTile />
        <OrderDetailsTile />
        <OrderDetailsTile />
        <OrderDetailsTile />
        <OrderDetailsTile />
        <OrderDetailsTile />
      </div> */}
    </>
  );
}
