import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/HomePage";
import ProductListPage from "./Pages/ProductListPage";
import CartPage from "./Pages/CartPage";
import OrderSummayPage from "./Pages/OrderSummayPage";
import OrderedProductPage from "./Pages/OrderedProductPage";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import FAQsPage from "./Pages/FAQsPage";
import LoginSignUp from "./Components/Forms/LoginSignUp";
import AdminLogin from "./Components/Forms/AdminLogin";
import NavbarAdmin from "./Components/Navbar/NavbarAdmin";
import AdminHomePage from "./Admin/AdminHomePage";
import AdminViewOrders from "./Admin/AdminViewOrders";
import AdminViewProducts from "./Admin/AdminViewProducts";
import AdminProductDetails from "./Admin/AdminProductDetails";
import AdminAddNewProduct from "./Admin/AdminAddNewProduct";
import AdminSiteInfo from "./Admin/AdminSiteInfo";
import AdminQueries from "./Admin/AdminQueries";
import UserProfile from "./Pages/UserProfile";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import Loader from "./Components/Loader/loader";
import { Toaster } from "react-hot-toast";
import MyState from "./Context/myState";
import { ProtectedRouteForUser } from "./ProtectedRoute/ProtectedRouteForUser";
import SearchResultPage from "./Pages/SearchResultPage";


function CustomerPage() {
  return (
    <MyState>
      <Router>
        <ScrollTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop/:categoryname" element={<ProductListPage />} />
            <Route path="/shop/searchQuery/:searchvalue" element={<SearchResultPage />} />
            <Route path="/productinfo/:id" element={<ProductDetailsPage />} />
            <Route path="/authentication" element={<LoginSignUp />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/faq" element={<FAQsPage />} />
            <Route
              path="/cart"
              element={
                <ProtectedRouteForUser>
                  <CartPage />
                </ProtectedRouteForUser>
              }
            />
            <Route
              path="/orderSummary"
              element={
                <ProtectedRouteForUser>
                  <OrderSummayPage />
                </ProtectedRouteForUser>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRouteForUser>
                  <OrderedProductPage />
                </ProtectedRouteForUser>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRouteForUser>
                  <UserProfile />
                </ProtectedRouteForUser>
              }
            />
          </Routes>
        <Toaster />
      </Router>
    </MyState>
  );
}

export default CustomerPage;
