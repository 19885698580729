import React, { useContext, useState } from "react";
import myContext from "../../Context/myContext";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { fireDB } from "../../firebase/FirebaseConfig";
import Loader from "../Loader/loader";

export default function ContactUs() {
  const context = useContext(myContext);
  const { loading, setLoading } = context;
  const navigate = useNavigate();
  const [messages, setMessages] = useState({
    yourName: "",
    yourEmail: "",
    yourSubject: "",
    yourMessage: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const sendMessage = async () => {
    if (
      messages.yourName === "" ||
      messages.yourEmail === "" ||
      messages.yourMessage === ""
    ) {
      return toast.error("all fields are required");
    }

    setLoading(true);
    try {
      const messagesRef = collection(fireDB, "queries");
      await addDoc(messagesRef, messages);
      toast.success("Message Sent Successfully!");
      navigate("/");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Error Sending Message!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessages((prevMessage) => ({
      ...prevMessage,
      [name]: value,
    }));
  };

  return (
    <div className="w-full py-3 px-2 flex font-custom12 items-center justify-center h-auto">
      <div className="w-3/4 notepad:w-full py-4 rounded-2xl shadow-light-shadow bg-red-50 px-5 flex flex-col items-center justify-center">
        <div className="h-1/6 w-full py-2 flex items-center justify-center gap-3">
          <div className="text-xl font-semibold notepad:text-center text-text-color-red">
            Please get in touch and we will be happy to help you
          </div>
        </div>
        <div className="w-full notepad:h-auto h-5/6 flex flex-col items-center justify-evenly pb-2 pt-4">
          <div className=" w-full px-4 notepad:px-0 h-full flex flex-col items-center justify-center gap-7">
            <div className="flex items-center notepad:flex-col justify-center w-full gap-5">
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="yourName"
                  className="cursor-pointer px-3 font-semibold"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  name="yourName"
                  id="yourName"
                  value={messages.yourName}
                  onChange={handleInputChange}
                  placeholder="John Smith"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="yourEmail"
                  className="cursor-pointer px-3 font-semibold"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  name="yourEmail"
                  id="yourEmail"
                  value={messages.yourEmail}
                  onChange={handleInputChange}
                  placeholder="john.smith@example.com"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 h-20 w-full">
              <label
                htmlFor="yourSubject"
                className="cursor-pointer px-3 font-semibold"
              >
                Your Subject
              </label>
              <input
                type="text"
                name="yourSubject"
                id="yourSubject"
                value={messages.yourSubject}
                onChange={handleInputChange}
                placeholder="type here..."
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="yourMessage"
                className="cursor-pointer px-3 font-semibold"
              >
                Your Message
              </label>
              <textarea
                type="text"
                name="yourMessage"
                id="yourMessage"
                value={messages.yourMessage}
                onChange={handleInputChange}
                placeholder="type here..."
                className="outline-none resize-none rounded-md px-4 py-4 h-56 border-[1.6px] border-red-200 focus:border-blue-400"
                required
              />
            </div>
            <div className="w-full flex items-center notepad:justify-center justify-end">
              <button
                onClick={() => {
                  sendMessage();
                }}
                className="px-4 py-2 w-48 h-12 rounded-lg hover:bg-btn-color-red-hover flex items-center justify-center active:bg-btn-color-red-active bg-btn-color-red transition-all duration-300 ease-in-out text-lg  text-white shadow-md"
              >
                {loading && <Loader/>}
                {!loading && <p>Send Message</p>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
