import React from "react";
import coverPic from "../../Assets/images/sample/sample4.png"
import { useNavigate } from "react-router-dom";

export default function HeadCover() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[32rem] -top-14 bg-cover-color relative">
        <div className="absolute w-full h-full">
            <img src={coverPic} className="w-full h-full object-cover opacity-20" alt="" />
        </div>
      <div className="absolute z-100 w-full h-full pt-14 pb-4 px-4 flex justify-center items-center">
        <div className="w-2/3 mobile:w-full mobile:text-center mobile:px-3 px-4 py-8 flex items-center justify-center flex-col gap-8 text-white">
          <div className="text-4xl mobile:text-3xl font-custom3 font-semibold">
            Discover Elegance in Every Thread{" "}
          </div>
          <div className="text-3xl mobile:text-2xl font-serif font-bold text-center">
            Exclusive Salwar Suits & Sarees Just for You!
          </div>
          <div className="text-center text-lg mobile:text-base font-custom9 mobile:px-2 px-8 font-light">
            Unveil the beauty of tradition with our curated collection of salwar
            suits and sarees. Shop now for timeless fashion that complements
            your style.
          </div>
          <div className="font-custom8 relative bg-btn-color-red px-5 flex items-center justify-center gap-2 py-2 shadow-md rounded-lg cursor-pointer text-white overflow-hidden group active:border-none">
            <span onClick={() => {navigate("/shop/Latest")}} className="relative z-10 transition-colors duration-300 group-hover:text-text-color-red font-semibold">
              Latest Collection
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="relative z-10 transition-colors duration-300 size-5 text-white group-hover:text-red-800"
            >
              <path
                fillRule="evenodd"
                d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
            <div className="absolute inset-0 bg-white transform translate-y-full transition-transform duration-100 group-hover:translate-y-0 group-active:bg-gray-400"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
