import React from "react";
import { useNavigate } from "react-router-dom";

export default function Headers(props) {
  const navigate = useNavigate(-1);
  return (
    <div className="w-full h-16 flex gap-6 mobile:gap-3 items-center px-6">
      <div
        onClick={() => navigate(-1)}
        className="w-10 h-10 rounded-md flex items-center justify-center shadow-lightest-shadow cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition-all duration-300 overflow-hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-6 text-btn-color-red"
        >
          <path
            fillRule="evenodd"
            d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="text-2xl mobile:text-xl font-custom10 text-btn-color-red">
        {props.heading}
      </div>
    </div>
  );
}
