import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { fireDB } from "../../firebase/FirebaseConfig";

export default function UserDetails() {
  const user = JSON.parse(localStorage.getItem("users"));
  const [getAllUserDetails, setGetAllUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    houseNo: "",
    areaRoad: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    phoneNo: "",
    altPhoneNo: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const getAllUserDetailsFunc = async () => {
    setLoading(true);
    try {
      const q = query(collection(fireDB, "userdetails"), orderBy("time"));
      const data = onSnapshot(q, (QuerySnapshot) => {
        let detailsArray = [];
        QuerySnapshot.forEach((doc) => {
          detailsArray.push({ ...doc.data(), id: doc.id });
        });
        setGetAllUserDetails(detailsArray);
        setLoading(false);
      });
      return () => data;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleChnage = async () => {
    try {
      const detailsRef = doc(fireDB, "userdetails", )
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }

  useEffect(() => {
    getAllUserDetailsFunc();
    getAllUserDetails
      .filter((obj) => obj.userid === user?.uid)
      .map((details) => {
        setAddressInfo({
          firstName: details.addressInfo.firstName,
          lastName: details.addressInfo.lastName,
          phoneNo: details.addressInfo.phoneNo,
          altPhoneNo: details.addressInfo.altPhoneNo,
          emailId: details.addressInfo.emailId,
          houseNo: details.addressInfo.houseNo,
          areaRoad: details.addressInfo.areaRoad,
          landmark: details.addressInfo.landmark,
          city: details.addressInfo.city,
          state: details.addressInfo.state,
          pincode: details.addressInfo.pincode,
        });
      });
  }, []);

  return (
    <div className="w-full h-[40rem] notepad:h-full px-10 font-custom12 py-6 flex flex-col items-center justify-center gap-5 notepad:px-4">
      <div className="w-full h-full flex flex-col justify-around items-center bg-red-50 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6">
        <div className="flex items-center justify-center w-full gap-5 notepad:flex-col">
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="firstName"
              className="cursor-pointer px-3 font-custom10"
            >
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={addressInfo.firstName}
              onChange={handleInputChange}
              placeholder="Nikhil"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="lastName"
              className="cursor-pointer px-3 font-custom10"
            >
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={addressInfo.lastName}
              onChange={handleInputChange}
              placeholder="Bhat"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="emailId"
              className="cursor-pointer px-3 font-custom10"
            >
              Email ID
            </label>
            <input
              type="email"
              name="emailId"
              id="emailId"
              value={addressInfo.emailId}
              onChange={handleInputChange}
              placeholder="nikhil.bhat@example.com"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="phoneNo"
              className="cursor-pointer px-3 font-custom10"
            >
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneNo"
              id="phoneNo"
              value={addressInfo.phoneNo}
              onChange={handleInputChange}
              placeholder="9812345678"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="altPhoneNo"
              className="cursor-pointer px-3 font-custom10"
            >
              Alternate Phone Number
            </label>
            <input
              type="tel"
              name="altPhoneNo"
              id="altPhoneNo"
              value={addressInfo.altPhoneNo}
              onChange={handleInputChange}
              placeholder="9876543210"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="houseNo"
              className="cursor-pointer px-3 font-custom10"
            >
              House/Apartment/Building
            </label>
            <input
              type="text"
              name="houseNo"
              id="houseNo"
              value={addressInfo.houseNo}
              onChange={handleInputChange}
              placeholder="Flat No. 15B, Oakwood Apartment"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="areaRoad"
              className="cursor-pointer px-3 font-custom10"
            >
              Area/Road/Locality
            </label>
            <input
              type="text"
              name="areaRoad"
              id="areaRoad"
              value={addressInfo.areaRoad}
              onChange={handleInputChange}
              placeholder="MG Road, Sector 21"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="landmark"
              className="cursor-pointer px-3 font-custom10"
            >
              Landmark
            </label>
            <input
              type="text"
              name="landmark"
              id="landmark"
              value={addressInfo.landmark}
              onChange={handleInputChange}
              placeholder="Near Central Park"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label htmlFor="city" className="cursor-pointer px-3 font-custom10">
              City
            </label>
            <input
              type="text"
              name="city"
              id="city"
              value={addressInfo.city}
              onChange={handleInputChange}
              placeholder="Gurugram"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="state"
              className="cursor-pointer px-3 font-custom10"
            >
              State
            </label>
            <input
              type="text"
              name="state"
              id="state"
              value={addressInfo.state}
              onChange={handleInputChange}
              placeholder="Haryana"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
          <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
            <label
              htmlFor="pincode"
              className="cursor-pointer px-3 font-custom10"
            >
              Pincode
            </label>
            <input
              type="text"
              name="pincode"
              id="pincode"
              value={addressInfo.pincode}
              onChange={handleInputChange}
              placeholder="122001"
              className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
              required
            />
          </div>
        </div>
      </div>
      <div className="w-full h-20 flex items-center justify-end">
        <button
          type="submit"
          className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover transition-all duration-300 ease-in-out text-lg tracking-wide bg-btn-color-green active:bg-btn-color-green-active text-white"
        >
          Save
        </button>
      </div>
    </div>
  );
}
