import React, { useContext, useState } from "react";
import samplePic1 from "../../Assets/images/sample/sample3.jpeg";
import samplePic2 from "../../Assets/images/sample/sample2.jpg";
import logo from "../../Assets/images/logo/RectangleRed.svg";
import toast from "react-hot-toast";
import myContext from "../../Context/myContext";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, fireDB } from "../../firebase/FirebaseConfig";
import Loader from "../Loader/loader";
import { useNavigate } from "react-router-dom";

export default function LoginSignUp() {
  const [login, setLogin] = useState(true);
  const context = useContext(myContext);
  const { loading, setLoading } = context;
  const navigate = useNavigate();

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const [userSignup, setUserSignup] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confpass: "",
    role: "user",
  });

  const userLoginFunc = async () => {
    if (userLogin.email === "" || userLogin.password === "") {
      return toast.error("All fields are required!");
    }
    setLoading(true);
    try {
      const users = await signInWithEmailAndPassword(
        auth,
        userLogin.email,
        userLogin.password
      );
      try {
        const q = query(
          collection(fireDB, "user"),
          where("uid", "==", users?.user?.uid)
        );
        const data = onSnapshot(q, (QuerySnapshot) => {
          let user;
          QuerySnapshot.forEach((doc) => (user = doc.data()));
          localStorage.setItem("users", JSON.stringify(user));
          setUserLogin({
            email: "",
            password: "",
          });
          toast.success("Logged In");
          setLoading(false);
          navigate("/");
        });
        return () => data;
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const userSignupFunc = async () => {
    if (userSignup.password !== userSignup.confpass) {
      return toast.error("Passwords do not matched");
    }
    setLoading(true);
    try {
      const users = await createUserWithEmailAndPassword(
        auth,
        userSignup.email,
        userSignup.password
      );

      const user = {
        firstname: userSignup.firstname,
        lastname: userSignup.lastname,
        email: users.user.email,
        uid: users.user.uid,
        role: userSignup.role,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      };

      const userReference = collection(fireDB, "user");

      await addDoc(userReference, user);

      setUserSignup({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confpass: "",
        role: "user",
      });

      toast.success("Signed Up Successfully");

      setLoading(false);
      setLogin(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden m-0 bg-gray-300 flex items-center justify-center relative">
      <div className="absolute w-full h-full">
        <img
          src={samplePic1}
          className={`w-full h-full object-cover transition-opacity duration-500 ease-in-out ${
            login ? "opacity-40" : "opacity-0"
          }`}
          alt=""
        />
        <img
          src={samplePic2}
          className={`w-full h-full object-cover transition-opacity duration-500 ease-in-out absolute top-0 left-0 ${
            login ? "opacity-0" : "opacity-40"
          }`}
          alt=""
        />
      </div>
      <div className="absolute w-full h-full z-100 bg-transparent flex items-center justify-center mobile:px-3">
        <div
          className={`${
            login
              ? "w-[32rem] h-[28rem] py-6"
              : "w-[38rem] h-[40rem] py-2 mobile:w-full mobile:h-auto mobile:py-2"
          } rounded-2xl shadow-light-shadow bg-slate-50 px-5 mobile:px-2 flex flex-col items-center justify-center transition-all duration-500 ease-in-out`}
        >
          <div
            className={`${
              login ? "h-1/6" : "h-16"
            } w-full flex items-center justify-center gap-3 transition-all duration-500 ease-in-out`}
          >
            <div className="w-9 h-9  border-2 border-red-500 rounded-lg">
              <img src={logo} className="w-full h-full object-cover" alt="" />
            </div>
            <div className="text-4xl font-semibold text-red-800 font-custom1">
              Raajhans
            </div>
          </div>
          <div className="w-full h-5/6  flex flex-col items-center justify-evenly pb-2">
            {login && (
              <div className="font-custom12 w-full px-4 h-5/6 flex flex-col items-center justify-between transition-all duration-500 ease-in-out">
                <div className="flex flex-col  gap-1 h-20 w-full">
                  <label
                    htmlFor="emailID"
                    className="cursor-pointer px-3 font-semibold"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    name="emailID"
                    id="emailID"
                    value={userLogin.email}
                    onChange={(e) => {
                      setUserLogin({
                        ...userLogin,
                        email: e.target.value,
                      });
                    }}
                    autoComplete="off"
                    placeholder="john.smith@example.com"
                    className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    required
                  />
                </div>
                <div className="flex flex-col  gap-1 h-20 w-full">
                  <label
                    htmlFor="passWord"
                    className="cursor-pointer px-3 font-semibold"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="passWord"
                    id="passWord"
                    value={userLogin.password}
                    onChange={(e) => {
                      setUserLogin({
                        ...userLogin,
                        password: e.target.value,
                      });
                    }}
                    autoComplete="off"
                    placeholder="123456789"
                    className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    required
                  />
                </div>
                <div className="w-full  h-20 flex items-center justify-end">
                  <button
                    onClick={userLoginFunc}
                    className="px-8 py-2 w-full rounded-lg flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg font-normal hover:bg-btn-color-red-hover active:bg-btn-color-red-active bg-btn-color-red text-white shadow-md"
                  >
                    {!loading && <span>Login</span>}
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            )}
            {!login && (
              <div className=" font-custom12 w-full px-4 flex flex-col items-center justify-between mobile:justify-center mobile:gap-5 grow py-4 transition-all duration-500 ease-in-out">
                <div className="flex items-center justify-center w-full gap-5 mobile:flex-col">
                  <div className="flex flex-col  gap-1 h-20 grow mobile:w-full">
                    <label
                      htmlFor="firstName"
                      className="cursor-pointer px-3 font-semibold"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      autoComplete="off"
                      value={userSignup.firstname}
                      onChange={(e) => {
                        setUserSignup({
                          ...userSignup,
                          firstname: e.target.value,
                        });
                      }}
                      placeholder="John"
                      className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                      required
                    />
                  </div>
                  <div className="flex flex-col  gap-1 h-20 grow mobile:w-full">
                    <label
                      htmlFor="lastName"
                      className="cursor-pointer px-3 font-semibold"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      autoComplete="off"
                      value={userSignup.lastname}
                      onChange={(e) => {
                        setUserSignup({
                          ...userSignup,
                          lastname: e.target.value,
                        });
                      }}
                      placeholder="Smith"
                      className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    />
                  </div>
                </div>
                <div className="flex flex-col  gap-1 h-20 w-full">
                  <label
                    htmlFor="emailIDSignUp"
                    className="cursor-pointer px-3 font-semibold"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    name="emailIDSignUp"
                    id="emailIDSignUp"
                    autoComplete="off"
                    value={userSignup.email}
                    onChange={(e) => {
                      setUserSignup({
                        ...userSignup,
                        email: e.target.value,
                      });
                    }}
                    placeholder="john.smith@example.com"
                    className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    required
                  />
                </div>
                <div className="flex flex-col  gap-1 h-20 w-full">
                  <label
                    htmlFor="passWordSignUp"
                    className="cursor-pointer px-3 font-semibold"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="passWordSignUp"
                    id="passWordSignUp"
                    autoComplete="off"
                    value={userSignup.password}
                    onChange={(e) => {
                      setUserSignup({
                        ...userSignup,
                        password: e.target.value,
                      });
                    }}
                    placeholder="123456789"
                    className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    required
                  />
                </div>
                <div className="flex flex-col  gap-1 h-20 w-full">
                  <label
                    htmlFor="confirmPassWord"
                    className="cursor-pointer px-3 font-semibold"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassWord"
                    id="confirmPassWord"
                    autoComplete="off"
                    value={userSignup.confpass}
                    onChange={(e) => {
                      setUserSignup({
                        ...userSignup,
                        confpass: e.target.value,
                      });
                    }}
                    placeholder="123456789"
                    className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-end">
                  <button
                    onClick={userSignupFunc}
                    className="px-8 py-2 w-full rounded-lg hover:bg-btn-color-red-hover active:bg-btn-color-red-active bg-btn-color-red flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg font-normal text-white shadow-md"
                  >
                    {!loading && <span>Sign Up</span>}
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            )}
            {login && (
              <div
                onClick={() => setLogin(false)}
                className="font-serif text-sm cursor-pointer hover:underline text-blue-600 w-full px-6 flex items-center justify-end"
              >
                Don't have an account? Sign Up
              </div>
            )}
            {!login && (
              <div
                onClick={() => setLogin(true)}
                className="font-serif text-sm cursor-pointer hover:underline text-blue-600 w-full px-6 flex items-center justify-end"
              >
                Already a user? Login
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
