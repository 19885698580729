import React, { useState, useEffect } from "react";
import { useContext } from "react";
import myContext from "../../Context/myContext";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { fireDB, storage } from "../../firebase/FirebaseConfig";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Loader from "../Loader/loader";

export default function UpdateProducts() {
  const [images, setImages] = useState(Array(6).fill(null));
  const context = useContext(myContext);
  const { loading, setLoading, getAllProductFunction } = context;
  const navigate = useNavigate();
  const { id } = useParams();

  const handleImageChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);

      const imageRef = ref(storage, `images/${uuidv4()}`);
      await uploadBytes(imageRef, file);
      const url = await getDownloadURL(imageRef);

      setProduct((prevProduct) => ({
        ...prevProduct,
        imageUrls: [
          ...prevProduct.imageUrls.slice(0, index),
          url,
          ...prevProduct.imageUrls.slice(index + 1),
        ],
      }));
    }
  };

  const [product, setProduct] = useState({
    title: "",
    description: "",
    origPrice: "",
    sellingPrice: "",
    size: [],
    highlights: [],
    imageUrls: [],
    quantity: 1,
    categories: [],
    type: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const getSingleProductFunction = async () => {
    setLoading(true);
    try {
      const productTemp = await getDoc(doc(fireDB, "products", id));
      //   console.log(product.data())
      const product = productTemp.data();
      setProduct({
        title: product?.title,
        origPrice: product?.origPrice,
        sellingPrice: product?.sellingPrice,
        type: product?.type,
        highlights: product?.highlights,
        imageUrls: product?.imageUrls,
        size: product?.size,
        categories: product?.categories,
        description: product?.description,
        quantity: product?.quantity,
        time: product?.time,
        date: product?.date,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleProductFunction();
  }, []);

  const updateProduct = async () => {
    setLoading(true)
    try {

        await setDoc(doc(fireDB, 'products', id), product)
        toast.success("Product Updated successfully")
        getAllProductFunction();
        setLoading(false)
        navigate('/admin')

    } catch (error) {
        console.log(error)
        setLoading(false)
    }
}

  const [inputFields, setInputFields] = useState(["", ""]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: [...prevProduct[name], value],
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: prevProduct[name].filter((item) => item !== value),
      }));
    }
  };

  const handleInputFieldChange = (e, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index] = e.target.value;
    setInputFields(newInputFields);
    setProduct((prevProduct) => ({
      ...prevProduct,
      highlights: newInputFields,
    }));
  };

  const removeInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
    setProduct((prevProduct) => ({
      ...prevProduct,
      highlights: newInputFields,
    }));
  };

  const addInputField = () => {
    setInputFields([...inputFields, ""]);
  };

  useEffect(() => {
    // Cleanup function to revoke object URLs
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image));
    };
  }, [images]);

  return (
    <div className="w-full px-4 py-4 font-custom12">
      <div className="w-full px-10 notepad:px-2 notepad:py-1 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden notepad:px-3 px-6 py-6 gap-4">
          <div className="flex notepad:flex-col items-center justify-center w-full gap-5">
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="title"
                className="cursor-pointer px-3 font-semibold"
              >
                Product Title
              </label>
              <input
                type="text"
                name="title"
                id="productName"
                value={product.title}
                onChange={handleInputChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="description"
                className="cursor-pointer px-3 font-semibold"
              >
                Product Description
              </label>
              <input
                type="text"
                name="description"
                id="descp"
                value={product.description}
                onChange={handleInputChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-full gap-5 notepad:flex-col">
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="origPrice"
                className="cursor-pointer px-3 font-semibold"
              >
                Orginal Price
              </label>
              <input
                type="text"
                name="origPrice"
                id="origPrice"
                value={product.origPrice}
                onChange={handleInputChange}
                placeholder="11999"
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="sellingPrice"
                className="cursor-pointer px-3 font-semibold"
              >
                Selling Price
              </label>
              <input
                type="text"
                name="sellingPrice"
                id="sellingPrice"
                value={product.sellingPrice}
                onChange={handleInputChange}
                placeholder="5799"
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="quantity"
                className="cursor-pointer px-3 font-semibold"
              >
                Quantity
              </label>
              <input
                type="number"
                name="quantity"
                id="Quantity"
                value={product.quantity}
                onChange={handleInputChange}
                placeholder="7"
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-full notepad:flex-col gap-5">
            <div className="flex flex-col gap-3 grow">
              <label className="cursor-pointer px-3 font-semibold">
                Sizes Available
              </label>
              <div className="flex justify-around px-3 notepad:flex-wrap notepad:gap-6">
                {["S", "M", "L", "XL", "XXL"].map((size) => (
                  <label key={size} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="size"
                      value={size}
                      checked={product.size.includes(size)}
                      onChange={handleCheckboxChange}
                      className="h-4 w-4 cursor-pointer"
                    />
                    {size}
                  </label>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1 grow h-20 notepad:w-full">
              <label
                htmlFor="type"
                className="cursor-pointer px-3 font-semibold"
              >
                Product Type
              </label>
              <select
                name="type"
                id="productType"
                value={product.type}
                onChange={handleInputChange}
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400 cursor-pointer"
                defaultValue="saree"
              >
                <option value="saree">Saree</option>
                <option value="suit">Suit</option>
              </select>
            </div>
            <div className="flex flex-col gap-3 grow notepad:w-full">
              <label className="cursor-pointer px-3 font-semibold">
                Categories
              </label>
              <div className="flex justify-around px-3 notepad:flex-col">
                {["Latest", "Trending", "Recommended", "Popular"].map(
                  (category) => (
                    <label key={category} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="categories"
                        value={category}
                        checked={product.categories.includes(category)}
                        onChange={handleCheckboxChange}
                        className="h-4 w-4 cursor-pointer"
                      />
                      {category}
                    </label>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-center w-full gap-3">
            <div className="cursor-pointer px-3 font-semibold">Add Images</div>
            <div className="flex items-center justify-between w-full notepad:flex-wrap notepad:gap-2">
              {[...Array(6)].map((_, index) => (
                <div
                  key={index}
                  className="w-40 h-56 notepad:w-20 notepad:h-28 bg-gray-100 border-2 border-red-300 rounded-lg active:bg-gray-300 transition-all duration-200 hover:bg-gray-200 flex items-center justify-center relative cursor-pointer"
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={(e) => handleImageChange(e, index)}
                  />
                  {images[index] ? (
                    <img
                      src={URL.createObjectURL(images[index])}
                      alt={`Selected image ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <span className="text-2xl font-bold text-gray-500">+</span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col gap-3 items-center">
            {inputFields.map((input, index) => (
              <div key={index} className="flex flex-col gap-1 w-full">
                <label className="font-semibold px-2">
                  Add Highlight: {index + 1}
                </label>
                <div className="flex items-center gap-3">
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => handleInputFieldChange(e, index)}
                    className="w-full h-12 outline-none rounded-md px-4 border-[1.6px] border-gray-400 focus:border-red-400"
                    placeholder={`Highlight ${index + 1}`}
                  />
                  {inputFields.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeInputField(index)}
                      className="text-btn-color-red-hover text-2xl font-bold bg-gray-200 px-2 py-2 rounded-full hover:bg-gray-100 active:bg-gray-200 transition-all duration-300
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={addInputField}
              className="text-btn-color-green-hover text-2xl font-bold text-center bg-gray-200 px-2 py-2 rounded-full hover:bg-gray-100 active:bg-gray-200 transition-all duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="w-full h-20 flex items-center justify-end">
          <button
            onClick={updateProduct}
            className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
          >
            {!loading && <span>Update</span>}
            {loading && <Loader />}
          </button>
        </div>
      </div>
    </div>
  );
}
