import React, { useEffect, useState } from "react";
import Headings from "../Components/Headers/Headings";
import UserDetails from "../Components/Forms/UserDetails";
import OrderSummary from "../Components/Content/OrderSummary";
import OrderTile from "../Components/Tiles/OrderTile";
import Headers from "../Components/Headers/Headers";
import Navbar from "../Components/Navbar/Navbar";
import { deleteFromCart } from "../redux/cartSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";

export default function OrderSummayPage() {
  const cartItems = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCart = (item) => {
    dispatch(deleteFromCart(item));
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const cartItemTotal = cartItems
    .map((item) => Number(item.quantity))
    .reduce((prevValue, currValue) => prevValue + currValue, 0);

  const cartTotal = cartItems
    .map((item) => Number(item.sellingPrice) * Number(item.quantity))
    .reduce((prevValue, currValue) => prevValue + currValue, 0);

  const cartOrigTotal = cartItems
    .map((item) => Number(item.origPrice) * Number(item.quantity))
    .reduce((prevValue, currValue) => prevValue + currValue, 0);

  const user = JSON.parse(localStorage.getItem("users"));

  const [addressInfo, setAddressInfo] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    houseNo: "",
    areaRoad: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    phoneNo: "",
    altPhoneNo: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const buyNowFunction = async () => {
    // validation
    if (
      addressInfo.firstName === "" ||
      addressInfo.houseNo === "" ||
      addressInfo.areaRoad === "" ||
      addressInfo.city === "" ||
      addressInfo.state === "" ||
      addressInfo.pincode === "" ||
      addressInfo.phoneNo === ""
    ) {
      return toast.error("All Fields are required");
    }

    var options = {
      key: process.env.REACT_APP_RZP_KEY,
      key_secret: process.env.REACT_APP_RZP_KEY_SECRET,
      amount: parseInt(cartTotal * 100),
      currency: "INR",
      order_receipt: "order_rcptid_" + addressInfo.firstName,
      name: "Raajhans",
      description: "for payment",
      handler: function (response) {
        // console.log(response);
        toast.success("Payment Successful");
        const paymentId = response.razorpay_payment_id;
        const orderInfo = {
          cartItems,
          addressInfo,
          email: user.email,
          userid: user.uid,
          totalAmount: cartTotal,
          paymentMode: "Paid via Razorpay",
          status: 1,
          time: Timestamp.now(),
          date: new Date().toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
          paymentId
        };
    
        const userDetails = {
          addressInfo,
          userid: user.uid,
          time: Timestamp.now(),
          date: new Date().toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
        };
    
        try {
          const orderRef = collection(fireDB, "order");
          addDoc(orderRef, orderInfo);
    
          const userDetailsRef = collection(fireDB, "userdetails");
          addDoc(userDetailsRef, userDetails);
    
          setAddressInfo({
            firstName: "",
            lastName: "",
            phoneNo: "",
            altPhoneNo: "",
            emailId: "",
            houseNo: "",
            areaRoad: "",
            landmark: "",
            city: "",
            state: "",
            pincode: "",
          });
          toast.success("Order Placed Successfully!");
          cartItems.map((item) => {
            deleteCart(item);
          });
          navigate("/");
        } catch (error) {
          console.log(error);
        }
        
      },
  
      theme: {
        color: "#3399cc",
      },
    };
  
    var pay = new window.Razorpay(options);
    pay.open();
   
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };



  return (
    <>
      <Navbar />
      <div className="my-14">
        <div className="w-full h-4"></div>
        <Headers heading={"Order Summary"} />
        <div className="w-full h-4"></div>
        <Headings heading={"Delivery Location"} />
        <div className="w-full h-[40rem] notepad:h-full px-10 font-custom12 py-6 flex flex-col items-center justify-center gap-5 notepad:px-4">
          <div className="w-full h-full flex flex-col justify-around items-center bg-red-50 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6">
            <div className="flex items-center justify-center w-full gap-5 notepad:flex-col">
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="firstName"
                  className="cursor-pointer px-3 font-custom10"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={addressInfo.firstName}
                  onChange={handleInputChange}
                  placeholder="Nikhil"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="lastName"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={addressInfo.lastName}
                  onChange={handleInputChange}
                  placeholder="Bhat"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="emailId"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Email ID
                </label>
                <input
                  type="email"
                  name="emailId"
                  id="emailId"
                  value={addressInfo.emailId}
                  onChange={handleInputChange}
                  placeholder="nikhil.bhat@example.com"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="phoneNo"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phoneNo"
                  id="phoneNo"
                  value={addressInfo.phoneNo}
                  onChange={handleInputChange}
                  placeholder="9812345678"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="altPhoneNo"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Alternate Phone Number
                </label>
                <input
                  type="tel"
                  name="altPhoneNo"
                  id="altPhoneNo"
                  value={addressInfo.altPhoneNo}
                  onChange={handleInputChange}
                  placeholder="9876543210"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="houseNo"
                  className="cursor-pointer px-3 font-custom10"
                >
                  House/Apartment/Building
                </label>
                <input
                  type="text"
                  name="houseNo"
                  id="houseNo"
                  value={addressInfo.houseNo}
                  onChange={handleInputChange}
                  placeholder="Flat No. 15B, Oakwood Apartment"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="areaRoad"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Area/Road/Locality
                </label>
                <input
                  type="text"
                  name="areaRoad"
                  id="areaRoad"
                  value={addressInfo.areaRoad}
                  onChange={handleInputChange}
                  placeholder="MG Road, Sector 21"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="landmark"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Landmark
                </label>
                <input
                  type="text"
                  name="landmark"
                  id="landmark"
                  value={addressInfo.landmark}
                  onChange={handleInputChange}
                  placeholder="Near Central Park"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                />
              </div>
            </div>
            <div className="flex items-center justify-center w-full gap-5 notepad:flex-col notepad:mt-5">
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="city"
                  className="cursor-pointer px-3 font-custom10"
                >
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={addressInfo.city}
                  onChange={handleInputChange}
                  placeholder="Gurugram"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="state"
                  className="cursor-pointer px-3 font-custom10"
                >
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={addressInfo.state}
                  onChange={handleInputChange}
                  placeholder="Haryana"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
                <label
                  htmlFor="pincode"
                  className="cursor-pointer px-3 font-custom10"
                >
                  Pincode
                </label>
                <input
                  type="text"
                  name="pincode"
                  id="pincode"
                  value={addressInfo.pincode}
                  onChange={handleInputChange}
                  placeholder="122001"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-blue-400"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-8 notepad:px-4 py-2 my-4">
          <div className="w-full -ml-3">
            <Headings heading={"Products"} />
          </div>
          <div className="flex items-center justify-start overflow-x-scroll">
            {cartItems.map((item, index) => {
              const { id, title, sellingPrice, imageUrls, quantity, size } =
                item;
              return (
                <div key={index} className="m-2 w-96 font-custom12">
                  <div
                    onClick={() => navigate(`/productinfo/${id}`)}
                    className="bg-tile-color overflow-hidden w-96 h-48 rounded-xl shadow-light-shadow flex cursor-pointer hover:bg-gray-50"
                  >
                    <div className="flex pl-4 py-4 pr-1 grow h-full gap-3">
                      <div className="h-full w-28 bg-yellow-300 rounded-lg overflow-hidden">
                        <img
                          src={imageUrls[0]}
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      </div>
                      <div className="h-full grow flex flex-col justify-evenly">
                        <p className="text-lg font-custom5">
                          {title.length > 16
                            ? `${title.slice(0, 14)}...`
                            : title}
                        </p>
                        <p>
                          <span className="font-bold">Item Price: </span>
                          {sellingPrice} Rs.
                        </p>
                        <p>
                          <span className="font-bold">Size: </span>
                          {size[0]}
                        </p>
                        <p>
                          <span className="font-bold">Qty: </span>
                          {quantity}
                        </p>
                        <p>
                          <span className="font-bold">Total Price: </span>
                          {Number(sellingPrice) * Number(quantity)} Rs.
                        </p>
                      </div>
                    </div>
                    <div className="h-full w-8 flex items-start justify-center py-2">
                      <div className="w-6 h-6 cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteCart(item);
                          }}
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6  rounded-full active:bg-gray-200 hover:bg-gray-100 cursor-pointer text-btn-color-red-hover px-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full px-4 py-4 flex flex-col items-center justify-center gap-1 my-2 font-custom12">
          <div className="w-1/2 notepad:w-full h-64 bg-tile-color-2 rounded-2xl shadow-light-shadow flex flex-col px-6 py-3">
            <p className="text-xl font-semibold px-1">Order Summary</p>
            <div className="w-full h-[0.1rem] my-1 bg-black rounded-full"></div>
            <div className="flex flex-col h-[9.5rem] justify-evenly">
              <div className="flex items-center justify-between">
                <p>Total Items</p>
                <p>{cartItemTotal}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Price</p>
                <p>{cartOrigTotal} Rs.</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Discount</p>
                <p className="text-green-500">
                  - {cartOrigTotal - cartTotal} Rs.
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p>Delivery Charges</p>
                <p>Free</p>
              </div>
            </div>
            <div className="w-full h-[0.1rem] my-1 bg-black rounded-full"></div>
            <div className="flex items-center justify-between">
              <p className="font-semibold text-lg">Total Amount</p>
              <p className="font-semibold text-lg">{cartTotal} Rs.</p>
            </div>
          </div>
        </div>
        <div className="mt-8 px-10 flex items-center justify-end">
          <div
            onClick={buyNowFunction}
            className="relative bg-btn-color-red-hover w-96 flex items-center shadow-md justify-center h-12 rounded-md cursor-pointer text-white overflow-hidden group active:border-none"
          >
            <span className="relative z-10 transition-colors duration-500 group-hover:text-white text-lg font-normal">
              Place Order
            </span>
            <div className="absolute inset-0 bg-btn-color-red transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 group-active:bg-btn-color-red-active"></div>
          </div>
        </div>
      </div>
    </>
  );
}
