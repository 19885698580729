import React, { useState, useEffect, useRef } from "react";

export default function Carousel(props) {
  const [counter, setCounter] = useState(0);
  const images = props.imageUrls || [];
  const slidesRef = useRef([]);

  useEffect(() => {
    slidesRef.current.forEach((slide, index) => {
      slide.style.left = `${index * 100}%`;
    });

    const slideImage = () => {
      slidesRef.current.forEach((slide) => {
        slide.style.transform = `translateX(-${counter * 100}%)`;
      });
    };

    const intervalId = setInterval(() => {
      setCounter((prevCounter) => (prevCounter + 1) % images.length);
    }, 6000);

    slideImage();

    return () => clearInterval(intervalId);
  }, [counter, images.length]);

  const handleNext = () => {
    setCounter((prevCounter) => (prevCounter + 1) % images.length);
  };

  const handlePrev = () => {
    setCounter((prevCounter) =>
      prevCounter === 0 ? images.length - 1 : prevCounter - 1
    );
  };

  return (
    <div className="h-full w-full flex py-2 gap-2">
      <div className="h-full notepad:h-auto w-10 flex items-center justify-center">
        <div
          onClick={handlePrev}
          className="flex items-center justify-center text-xl bg-tile-color-2 font-bold p-0 w-7 h-14 rounded-md shadow-sm cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-text-color-red transition-all duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div className="h-full grow rounded-2xl shadow-sm overflow-hidden flex relative">
        {images.map((image, index) => (
          <img
            key={index}
            ref={(el) => (slidesRef.current[index] = el)}
            className="w-full h-full object-cover slider transition duration-300 notepad:static absolute top-0 left-0"
            src={image}
            alt={`Image ${index + 1}`}
          />
        ))}
      </div>
      <div className="h-full w-10 notepad:h-auto flex items-center justify-center">
        <div
          onClick={handleNext}
          className="flex items-center justify-center text-xl font-bold p-0 w-7 h-14 bg-tile-color-2 rounded-md shadow-sm cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-text-color-red transition-all duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6"
          >
            <path
              fillRule="evenodd"
              d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
