import React from "react";
import Footer from "../Components/Footer/Footer";
import Headings from "../Components/Headers/Headings";
import Headers from "../Components/Headers/Headers";
import UserDetails from "../Components/Forms/UserDetails";
import AddressTile from "../Components/Tiles/AddressTile";
import Navbar from "../Components/Navbar/Navbar";

export default function UserProfile() {
  return (
    <>
    <Navbar />
      <div className="my-14">
        <div className="w-full h-2"></div>
        <Headers heading={"Your Profile"} />
        <div className="w-full h-2"></div>
        <Headings heading={"Add Your Details"} />
        <UserDetails />
        <div className="w-full h-6"></div>
        <Headings heading={"Saved Addresses"} />
        <div className="w-full px-4 py-2">
          <div className="flex overflow-x-scroll items-center justify-start gap-2">
            <AddressTile />
            <AddressTile />
            <AddressTile />
            <AddressTile />
            <AddressTile />
            <AddressTile />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
