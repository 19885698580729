import React, { useContext, useState } from "react";
import samplePic2 from "../../Assets/images/sample/sample2.jpg";
import logo from "../../Assets/images/logo/RectangleRed.svg";
import toast from "react-hot-toast";
import myContext from "../../Context/myContext";
import { auth, fireDB } from "../../firebase/FirebaseConfig";
import Loader from "../Loader/loader";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";

export default function AdminLogin() {
  const context = useContext(myContext);
  const { loading, setLoading } = context;
  const navigate = useNavigate();

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  const userLoginFunc = async () => {
    if (userLogin.email === "" || userLogin.password === "") {
      return toast.error("All fields are required!");
    }
    setLoading(true);
    try {
      const users = await signInWithEmailAndPassword(
        auth,
        userLogin.email,
        userLogin.password
      );
      try {
        const q = query(
          collection(fireDB, "user"),
          where("uid", "==", users?.user?.uid)
        );
        const data = onSnapshot(q, (QuerySnapshot) => {
          let user;
          QuerySnapshot.forEach((doc) => (user = doc.data()));
          localStorage.setItem("users", JSON.stringify(user));
          setUserLogin({
            email: "",
            password: "",
          });
          toast.success("Logged In");
          setLoading(false);
          navigate("/admin");
        });
        return () => data;
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden m-0 bg-gray-300 flex items-center justify-center relative">
      <div className="absolute w-full h-full">
        <img
          src={samplePic2}
          className={`w-full h-full object-cover transition-opacity duration-500 ease-in-out absolute top-0 left-0 opacity-40`}
          alt=""
        />
      </div>
      <div className="absolute w-full h-full z-100 bg-transparent flex items-center justify-center mobile:px-3">
        <div
          className={`w-[32rem] mobile:w-full py-6 rounded-2xl shadow-light-shadow bg-slate-50 px-4 mobile:px-2 flex flex-col gap-5 items-center justify-center transition-all duration-500 ease-in-out`}
        >
          <div
            className={`h-1/6 w-full flex items-center justify-center gap-3 transition-all duration-500 ease-in-out`}
          >
            <div className="w-10 h-10 border-2 border-red-500 rounded-lg">
              <img src={logo} className="w-full h-full object-cover" alt="" />
            </div>
            <div className="text-3xl font-custom1 text-red-800">
              Raajhans Admin
            </div>
          </div>
          <div className="w-full flex flex-col items-center justify-center pb-2 font-custom12">
            <div className="w-full px-4 h-full flex flex-col items-center justify-center gap-4 transition-all duration-500 ease-in-out">
              <div className="flex flex-col gap-1 h-20 w-full">
                <label
                  htmlFor="emailID"
                  className="cursor-pointer px-3 font-semibold"
                >
                  Email ID
                </label>
                <input
                  type="email"
                  name="emailID"
                  id="emailID"
                  value={userLogin.email}
                  onChange={(e) => {
                    setUserLogin({
                      ...userLogin,
                      email: e.target.value,
                    });
                  }}
                  placeholder="john.smith@example.com"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                  required
                />
              </div>
              <div className="flex flex-col gap-1 h-20 w-full">
                <label
                  htmlFor="passWord"
                  className="cursor-pointer px-3 font-semibold"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="passWord"
                  id="passWord"
                  value={userLogin.password}
                  onChange={(e) => {
                    setUserLogin({
                      ...userLogin,
                      password: e.target.value,
                    });
                  }}
                  placeholder="123456789"
                  className="outline-none rounded-md px-4 h-12 border-[1.6px] border-red-200 focus:border-blue-400"
                  required
                />
              </div>
              <div className="w-full flex items-center justify-end">
                <button
                  onClick={userLoginFunc}
                  className="px-8 py-2 w-full rounded-lg flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg  hover:bg-btn-color-red-hover active:bg-btn-color-red-active bg-btn-color-red text-white shadow-md"
                >
                  {!loading && <span>Login</span>}
                  {loading && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
