import React from "react";
import ContactUs from "../Components/Forms/ContactUs";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";

export default function ContactUsPage() {
  return (
    <>
      <Navbar />
      <div className="mt-14 py-6">
        <ContactUs />
      </div>
      <Footer />
    </>
  );
}
