import React from "react";
import Headings from "../Components/Headers/Headings";
import Navigation from "../Components/Navbar/Navigation";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";

export default function AdminHomePage() {
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headings heading={"Home Page"} />
      <div className="w-full flex flex-col gap-3 py-1">
        <Navigation title={"View Orders"} gotohere={"orders"} />
        <Navigation title={"All Products"} gotohere={"products"} />
        <Navigation title={"Add New Product"} gotohere={"addproducts"} />
        <Navigation title={"Update Site Info"} gotohere={"siteinfo"} />
        <Navigation title={"Queries and Emails"} gotohere={"queries"} />
      </div>
    </>
  );
}
