import React, { useContext, useEffect, useState } from "react";
import AboutUs from "../Components/Content/AboutUs";
import Headings from "../Components/Headers/Headings";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { fireDB } from "../firebase/FirebaseConfig";
import Loader from "../Components/Loader/loader";
import myContext from "../Context/myContext";

export default function AboutUsPage() {
  const [getAboutUs, setGetAboutUs] = useState([]);

  const getAboutUsFunction = async () => {
    try {
      const q = query(
        collection(fireDB, "AboutUs"),
        orderBy("time", "desc"),
        limit(1)
      );
      const data = onSnapshot(q, (QuerySnapshot) => {
        let detailsAboutArray = [];
        QuerySnapshot.forEach((doc) => {
          detailsAboutArray.push({ ...doc.data(), id: doc.id });
        });
        setGetAboutUs(detailsAboutArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAboutUsFunction();
  }, []);

  return (
    <>
      <Navbar />
      <div className="mt-14 mb-32">
        <div className="w-full h-6"></div>
        <Headings heading={"About Us"} />
        {getAboutUs.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full font-custom12 py-4 flex items-center justify-center"
            >
              <div className="w-11/12 rounded-md px-4 py-5 shadow-light-shadow bg-red-100 flex flex-col gap-4">
                <div className="w-full px-4 notepad:px-0 notepad:text-justify flex gap-1 items-center justify-start">
                  <p className="text-lg">{item.aboutUs}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
}
