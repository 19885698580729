import React, { useContext, useEffect, useState } from "react";
import logo from "../../Assets/images/logo/Raajhans.svg";
import { Link, useNavigate } from "react-router-dom";
import myContext from "../../Context/myContext";
import toast from "react-hot-toast";
import {
  addDoc,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import { fireDB } from "../../firebase/FirebaseConfig";
import Loader from "../Loader/loader";

export default function Footer() {
  const navigate = useNavigate();
  const [getAllShopDetails, setGetAllShopDetails] = useState([]);

  const getAllShopDetailsFunction = async () => {
    try {
      const q = query(
        collection(fireDB, "ShopDetails"),
        orderBy("time", "desc"),
        limit(1)
      );
      const data = onSnapshot(q, (QuerySnapshot) => {
        let detailsArray = [];
        QuerySnapshot.forEach((doc) => {
          detailsArray.push({ ...doc.data(), id: doc.id });
        });
        setGetAllShopDetails(detailsArray);
      });
      return () => data;
    } catch (error) {
      console.log(error);
    }
  };

  const [userEmail, setUserEmail] = useState({
    userEmailId: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const subscribe = async () => {
    if (
      userEmail.userEmailId === "" ||
      !userEmail.userEmailId.includes("@") ||
      !userEmail.userEmailId.includes(".")
    ) {
      return toast.error("Please enter a valid email!");
    }
    try {
      const userEmailRef = collection(fireDB, "userEmails");
      await addDoc(userEmailRef, userEmail);
      toast.success("Subscribed Successfully!");
      setUserEmail({
        userEmailId: "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Oops! Please try again later");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserEmail((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    getAllShopDetailsFunction();
  }, []);

  return (
    <div className="w-full h-[22rem] mobile:h-auto bg-blue-300 relative bottom-0 font-custom12 text-white">
      <div className="w-full h-72 mobile:h-auto mobile:items-start mobile:justify-center mobile:gap-2 bg-footer-bg-bottom flex mobile:flex-col">
        <div className="h-full w-5/12 mobile:w-full p-4 mobile:p-2 flex flex-col">
          <div className="w-full h-24 flex items-center justify-start p-3 gap-3">
            <div className="w-12 h-12">
              <img src={logo} alt="" className="w-full h-full object-cover" />
            </div>
            <div>
              <p className="text-4xl text-white font-custom1 font-bold">
                Raajhans
              </p>
            </div>
          </div>
          <div className="w-full h-24 flex flex-col justify-center items-start p-3">
            <p className="text-lg font-custom10">
              We have high standards for our emails too.
            </p>
            <p className="leading-tight tracking-tight font-custom8">
              Sign up for Raajhans updates to receive information about new
              collection arrivals, exclusive events, and special offers.
            </p>
          </div>
          <div className="w-full h-24 flex items-center justify-start p-3 mobile:px-3 mobile:pb-1 mobile:pt-2">
            <input
              type="text"
              name="userEmailId"
              value={userEmail.userEmailId}
              onChange={handleInputChange}
              className="w-2/3 h-[2.55rem] px-2 text-md text-black placeholder:font-custom7 placeholder:text-sm rounded-l-[0.25rem] shadow-sm focus:outline-none"
              placeholder="Please enter your email"
            />
            <div
              onClick={() => {
                subscribe();
              }}
              className="w-32 h-[2.55rem] bg-btn-color-green hover:bg-btn-color-green-hover active:bg-btn-color-green-active transition-all duration-300 cursor-pointer rounded-r-[0.25rem] shadow-sm text-white flex justify-center tracking-wider text-lg font-custom10 items-center"
            >
              SUBSCRIBE
            </div>
          </div>
        </div>
        <div className="h-full w-1/4 mobile:w-full mobile:py-2 px-4 py-6 flex items-center">
          <div className="w-full h-56 mobile:h-auto flex flex-col p-2 gap-1">
            <p className="font-custom5">CUSTOMER SERVICE</p>
            <p
              className="mx-2 cursor-pointer hover:underline font-custom8"
              onClick={() => navigate("/about")}
            >
              <span>About Us</span>
            </p>
            <p
              className="mx-2 cursor-pointer hover:underline font-custom8"
              onClick={() => navigate("/faq")}
            >
              <span>FAQ</span>
            </p>
            <p
              className="mx-2 cursor-pointer hover:underline font-custom8"
              onClick={() => navigate("/contact")}
            >
              <span>Contact Us</span>
            </p>
            <p className="mx-2 cursor-pointer hover:underline font-custom8">
              <a href="https://www.linkedin.com/in/harsh-maurya-39a102203/" target="_blank" rel="noopener noreferrer">
                Developer's Info
              </a>
            </p>
          </div>
        </div>
        <div className="h-full w-1/3 px-4 py-6 mobile:pt-1 mobile:pb-4 mobile:w-full flex items-center justify-center">
          <div className="w-full h-56 flex flex-col p-2 justify-between">
            <div className="flex flex-col gap-[0.05rem]">
              <p className="font-custom5">ADDRESS</p>
              <p className="leading-tight tracking-tight font-custom8">
                {getAllShopDetails.map((item, index) => {
                  return <span key={index}>{item.address}</span>;
                })}
              </p>
              <div className="w-10 h-10 m-2 items-center flex justify-center">
                {getAllShopDetails.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={item.mapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-brand-pinterest cursor-pointer px-1 w-10 h-10 transition-all duration-300 ease-in-out rounded-full hover:bg-white hover:text-btn-color-red"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7.5" />
                        <path d="M9 4v13" />
                        <path d="M15 7v5.5" />
                        <path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z" />
                        <path d="M19 18v.01" />
                      </svg>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="">
              <p className="font-custom5">FOLLOW US</p>
              <div className="flex gap-3 w-full items-center justify-start px-2">
                <div className="w-10 h-10 m-2 items-center flex justify-center">
                  {getAllShopDetails.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-brand-pinterest cursor-pointer px-1 w-10 h-10 transition-all duration-300 ease-in-out rounded-full hover:bg-white hover:text-btn-color-red"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                          <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                          <path d="M16.5 7.5l0 .01" />
                        </svg>
                      </a>
                    );
                  })}
                </div>
                <div className="w-10 h-10 m-2 items-center flex justify-center">
                  {getAllShopDetails.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-brand-pinterest cursor-pointer px-1 w-10 h-10 transition-all duration-300 ease-in-out rounded-full hover:bg-white hover:text-btn-color-red"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 10.174c1.766 -2.784 3.315 -4.174 4.648 -4.174c2 0 3.263 2.213 4 5.217c.704 2.869 .5 6.783 -2 6.783c-1.114 0 -2.648 -1.565 -4.148 -3.652a27.627 27.627 0 0 1 -2.5 -4.174z" />
                          <path d="M12 10.174c-1.766 -2.784 -3.315 -4.174 -4.648 -4.174c-2 0 -3.263 2.213 -4 5.217c-.704 2.869 -.5 6.783 2 6.783c1.114 0 2.648 -1.565 4.148 -3.652c1 -1.391 1.833 -2.783 2.5 -4.174z" />
                        </svg>
                      </a>
                    );
                  })}
                </div>
                <div className="w-10 h-10 m-2 items-center flex justify-center">
                  {getAllShopDetails.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.pinterest}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-brand-pinterest cursor-pointer px-1 w-10 h-10 transition-all duration-300 ease-in-out rounded-full hover:bg-white hover:text-btn-color-red"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 20l4 -9" />
                          <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7" />
                          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        </svg>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-16 mobile:h-auto mobile:py-1 bg-tile-color text-center font-custom1 text-black flex items-center justify-center">
        Copyright &#169; 2024 RaajHans Ltd. All rights reserved.
      </div>
    </div>
  );
}
