import React from "react";
import Headers from "../Components/Headers/Headers";
import AddProducts from "../Components/Forms/AddProducts";
import NavbarAdmin from "../Components/Navbar/NavbarAdmin";

export default function AdminAddNewProduct() {
  return (
    <>
      <NavbarAdmin />
      <div className="w-full h-20"></div>
      <Headers heading={"Add New Product"} />
      <AddProducts />
    </>
  );
}
