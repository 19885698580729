import React, { useEffect } from "react";
import samplePic from "../../Assets/images/sample/RMP_8630_zoom.jpeg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, deleteFromCart } from "../../redux/cartSlice";
import toast from "react-hot-toast";

export default function ItemTile(props) {
  const navigate = useNavigate();
  const item = props.item || {};
  const { id, title, origPrice, sellingPrice, imageUrls = [] } = item;
  const cartItems = useSelector((state) => state.cart) || [];
  const dispatch = useDispatch();
  const discount = Math.round(((origPrice - sellingPrice) / (origPrice)) * 100)
  const itemsToPush = JSON.parse(JSON.stringify(item));
  const availSizes = item.size || [];
  itemsToPush.size = availSizes.includes("M") ? ["M"] : [availSizes[0]];
  itemsToPush.quantity = 1;
  const addCart = (item) => {
    dispatch(addToCart(item));
    toast.success("Added to cart");
  };

  const deleteCart = (item) => {
    dispatch(deleteFromCart(item));
    toast.success("Deleted from cart");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <div className="w-64 m-3">
      <div
        className=" w-64 h-[28rem] rounded-xl flex flex-col shadow-light-shadow overflow-hidden cursor-pointer"
        onClick={() => navigate(`/productinfo/${id}`)}
      >
        <div className="w-full grow rounded-t-xl overflow-hidden">
          {imageUrls.length > 0 ? (
            <img
              src={imageUrls[0]}
              className="w-full h-full object-cover"
              alt={title}
            />
          ) : (
            <img
              src="" // Use a sample picture if imageUrls is empty
              className="w-full h-full object-cover"
              alt="Placeholder"
            />
          )}
        </div>
        <div className="w-full h-28 rounded-b-xl">
          <div className="w-full h-10 flex items-center px-2 py-1">
            <p className="w-full h-full font-custom5 overflow-hidden flex items-end whitespace-nowrap text-btn-color-red">
              {title}
            </p>
          </div>
          <div className="w-full px-2 py-1 flex justify-between items-center h-[4.5rem]">
            <div className="w-5/6 h-full flex flex-col items-start justify-start gap-[0.05rem]">
              <p className="px-1 font-custom10 text-text-color-green">
                {sellingPrice} Rs.
              </p>
              <p className="px-1">
                <span className="line-through font-custom10 text-text-color-gray">
                  {origPrice} Rs.
                </span>
                <span className="mx-2 font-custom12 text-text-color-red text-sm">
                  {discount}% off
                </span>
              </p>
            </div>
            <div className="w-1/6 h-full flex flex-col items-end justify-end py-1">
              <div className="w-8 h-8 flex items-center justify-center">
                {Array.isArray(cartItems) &&
                cartItems.some((p) => p.id === id) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCart(itemsToPush);
                    }}
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-7 text-btn-color-red px-1 rounded-full hover:bg-gray-100"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm3 10.5a.75.75 0 0 0 0-1.5H9a.75.75 0 0 0 0 1.5h6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={(e) => {
                      e.stopPropagation();
                      addCart(itemsToPush);
                    }}
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-7 text-btn-color-red px-1 rounded-full hover:bg-gray-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
