import React, { useContext } from "react";
import Headings from "../Components/Headers/Headings";
import Headers from "../Components/Headers/Headers";
import OrderStatusTile from "../Components/Tiles/OrderStatusTile";
import Navbar from "../Components/Navbar/Navbar";
import myContext from "../Context/myContext";
import Loader from "../Components/Loader/loader";

export default function OrderedProductPage() {
  const user = JSON.parse(localStorage.getItem("users"));

  const context = useContext(myContext);
  const { loading, getAllOrder } = context;

  

  return (
    <>
      <Navbar />
      <div className="my-14">
        <div className="w-full h-4"></div>
        <Headers heading={"Your Orders"} />
        {/* <div className="w-full h-5"></div>
        <Headings heading={"Recent Orders"} /> */}
        {loading && <Loader/>}
        {!loading && (<div className="flex flex-col w-full items-center justify-center">
          {getAllOrder
            .filter((obj) => obj.userid === user?.uid)
            .map((order, index) => {
              return (
                <div key={index} className="w-full">
                  {order.cartItems.map((item, index) => {
                    const { title, sellingPrice, size, quantity, imageUrls } =
                      item;
                    const { status } = order;
                    return (
                      <div
                        key={index}
                        className="w-full font-custom12 px-4 py-4 flex flex-col items-center justify-center gap-1 my-2 "
                      >
                        <div className="w-11/12 h-64 mobile:w-full mobile:h-auto bg-tile-color rounded-2xl shadow-light-shadow flex mobile:flex-col px-6 py-5 gap-4 hover:bg-gray-100 cursor-pointer transition-all duration-300 ease-in-out">
                          <div className="h-full w-48 mobile:w-full rounded-xl overflow-hidden cursor-pointer">
                            <img
                              src={imageUrls[0]}
                              className="w-full h-full object-cover"
                              alt=""
                            />
                          </div>
                          <div className="grow h-full flex items-center justify-center flex-col">
                            <div className="w-full h-4/5 mobile:h-auto mobile:flex-col flex items-center justify-center">
                              <div className="w-4/5 h-full py-3 px-2 mobile:w-full mobile:pt-0 rounded-xl">
                                <div className="h-full grow flex flex-col justify-start items-start gap-[0.1rem]">
                                  <p className="text-2xl mobile:text-xl font-semibold mb-1 text-text-color-red">
                                    {title}
                                  </p>
                                  <p>
                                    <span className="font-semibold text-text-color-red">
                                      Item Price:{" "}
                                    </span>
                                    <span className="text-text-color-light-black">
                                      {sellingPrice} Rs.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="font-semibold text-text-color-red">
                                      Size:{" "}
                                    </span>
                                    <span className="text-text-color-light-black">
                                      {size[0]}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="font-semibold text-text-color-red">
                                      Qty:{" "}
                                    </span>
                                    <span className="text-text-color-light-black">
                                      {quantity}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="font-semibold text-text-color-red">
                                      Total Price:{" "}
                                    </span>
                                    <span className="text-text-color-green font-semibold">
                                      {Number(quantity) * Number(sellingPrice)}{" "}
                                      Rs.
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="w-1/5 mobile:w-full mobile:items-center h-full flex flex-col items-end justify-start gap-1">
                                {/* <button className="px-3 py-2 rounded-md hover:bg-btn-color-red-hover active:bg-btn-color-red-active transition-all duration-100 ease-in-out text-sm font-light bg-btn-color-red text-white">
                                  Cancel Order
                                </button> */}
                              </div>
                            </div>
                            <div className="w-full h-1/5 mobile:hidden flex py-1 items-center justify-center">
                              <div className="bg-btn-color-green-hover items-center flex justify-center px-2 py-1 text-xs text-white rounded-full">
                                Order Placed
                              </div>
                              <div className="h-1 grow bg-btn-color-green-hover"></div>
                              <div
                                className={`h-1 grow ${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-2 py-1 text-xs text-white rounded-full`}
                              >
                                Order Confirmed
                              </div>
                              <div
                                className={`h-1 grow ${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`h-1 grow ${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-2 py-1 text-xs text-white rounded-full`}
                              >
                                Out For Delivery
                              </div>
                              {/* <div className="bg-btn-color-red-hover hidden items-center flex justify-center text-xs px-2 py-1 text-white rounded-full">
                                Cancellation Requested
                              </div> */}
                              <div
                                className={`h-1 grow ${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`h-1 grow ${
                                  status > 3
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 3
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-2 py-1 text-xs text-white rounded-full`}
                              >
                                Delivered
                              </div>
                              {/* <div className="bg-btn-color-red-hover hidden items-center flex justify-center px-2 text-xs py-1 text-white rounded-full">
                                Cancelled
                              </div> */}
                            </div>
                            <div className="w-full h-1/5 hidden mobile:flex pt-5 items-center justify-center">
                              <div className="bg-btn-color-green-hover items-center flex justify-center px-1 py-1 text-white rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6.912 3a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H6.912Zm13.823 9.75-2.213-7.191A1.5 1.5 0 0 0 17.088 4.5H6.912a1.5 1.5 0 0 0-1.434 1.059L3.265 12.75H6.11a3 3 0 0 1 2.684 1.658l.256.513a1.5 1.5 0 0 0 1.342.829h3.218a1.5 1.5 0 0 0 1.342-.83l.256-.512a3 3 0 0 1 2.684-1.658h2.844Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div className="h-1 grow bg-btn-color-green-hover"></div>
                              <div
                                className={`h-1 grow ${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-1 py-1 text-white rounded-full`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div
                                className={`h-1 grow ${
                                  status > 1
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`h-1 grow ${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-1 py-1 text-white rounded-full`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
                                  <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
                                  <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                </svg>
                              </div>
                              {/* <div className="bg-btn-color-red-hover hidden items-center flex justify-center px-1 py-1 text-white rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div> */}
                              <div
                                className={`h-1 grow ${
                                  status > 2
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`h-1 grow ${
                                  status > 3
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  status > 3
                                    ? "bg-btn-color-green-hover"
                                    : "bg-text-color-gray"
                                } items-center flex justify-center px-1 py-1 text-white rounded-full`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path d="M9.375 3a1.875 1.875 0 0 0 0 3.75h1.875v4.5H3.375A1.875 1.875 0 0 1 1.5 9.375v-.75c0-1.036.84-1.875 1.875-1.875h3.193A3.375 3.375 0 0 1 12 2.753a3.375 3.375 0 0 1 5.432 3.997h3.943c1.035 0 1.875.84 1.875 1.875v.75c0 1.036-.84 1.875-1.875 1.875H12.75v-4.5h1.875a1.875 1.875 0 1 0-1.875-1.875V6.75h-1.5V4.875C11.25 3.839 10.41 3 9.375 3ZM11.25 12.75H3v6.75a2.25 2.25 0 0 0 2.25 2.25h6v-9ZM12.75 12.75v9h6.75a2.25 2.25 0 0 0 2.25-2.25v-6.75h-9Z" />
                                </svg>
                              </div>
                              {/* <div className="bg-btn-color-red-hover hidden items-center flex justify-center px-1 py-1 text-white rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>)}
        {/* <div className="w-full h-8"></div>
        <Headings heading={"Past Orders"} />
        <div className="flex flex-col w-full items-center justify-center">
          <OrderStatusTile />
          <OrderStatusTile />
          <OrderStatusTile />
          <OrderStatusTile />
          <OrderStatusTile />
          <OrderStatusTile />
          <OrderStatusTile />
        </div> */}
      </div>
    </>
  );
}
