import React, { useState } from "react";

export default function AddressTile() {
  const [isDefault, setIsDefault] = useState(false);
  return (
      <div className="w-[48rem] m-2 font-custom12">
      <div
      onClick={() => {
        setIsDefault(true);
      }}
      className={`${
        isDefault ? "border-4 border-green-600" : ""
      } w-full shadow-md rounded-xl bg-slate-50 px-6 py-5 flex items-start justify-center gap-5 cursor-pointer hover:bg-gray-300 transition-all duration-300 ease-in-out`}
    >
      <div className="flex flex-col items-start justify-center gap-1 w-[25rem]">
        <p>
          <span className="font-bold mr-1">Full Name:</span>Nikhil Bhat
        </p>
        <p>
          <span className="font-bold mr-1">Email Id:</span>
          nikhil.bhat@example.com
        </p>
        <p>
          <span className="font-bold mr-1">Contact No.:</span>+91 9807654321
        </p>
        <p className="h-24 overflow-hidden text-justify">
          <span className="font-bold mr-1">Address:</span>Flat No. 15B, Oakwood
          Apartment, MG Road, Sector 21 (Near Central Park), Gurugram (122001),
          Haryana Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia hic quod atque pariatur vitae modi tempora provident, doloremque nulla ad.
        </p>
      </div>
      <div
        className={`bg-green-600 px-4 rounded-full text-white font-semibold py-1 items-center justify-center ${
          isDefault ? "flex" : "hidden"
        }`}
      >
        default
      </div>
    </div>
    </div>
  );
}
