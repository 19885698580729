import { addDoc, collection, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { fireDB } from "../../firebase/FirebaseConfig";
import toast from "react-hot-toast";

export default function SiteInformation() {
  const [images, setImages] = useState(Array(6).fill(null));
  const [aboutUs, setAboutUs] = useState({
    aboutUs: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const [shopDetails, setShopDetails] = useState({
    address: "",
    mapsLink: "",
    instagram: "",
    facebook: "",
    pinterest: "",
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const [faqs, setFaqs] = useState({
    FAQs: [],
    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);
    }
  };

  const [inputFields, setInputFields] = useState([
    { question: "", response: "" },
  ]);

  const handleInputFieldChange = (e) => {
    const { name, value } = e.target;
    setShopDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleInputFieldChangeAboutUs = (e) => {
    const { name, value } = e.target;
    setAboutUs((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const updateAboutUs = async () => {
    if (aboutUs.aboutUs === "") {
      return toast.error("Please enter some value");
    }

    try {
      const aboutUsRef = collection(fireDB, "AboutUs");
      await addDoc(aboutUsRef, aboutUs);
      toast.success("Updated successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Failed to Update!");
    }
  };

  const handleShopDetailsChange = async () => {
    if (
      shopDetails.address === "" ||
      shopDetails.instagram === "" ||
      shopDetails.facebook === "" ||
      shopDetails.pinterest === ""
    ) {
      return toast.error("all fields are required!");
    }

    try {
      const shopDetailsRef = collection(fireDB, "ShopDetails");
      await addDoc(shopDetailsRef, shopDetails);
      toast.success("Updated successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Failed to Update!");
    }
  };

  const handleFAQS = async () => {
    if (
      inputFields.length === 0 ||
      inputFields.some(
        (field) => field.question === "" || field.response === ""
      )
    ) {
      return toast.error("Please fill out all questions and responses!");
    }

    try {
      const faqsRef = collection(fireDB, "FaQs");
      await addDoc(faqsRef, { ...faqs, FAQs: inputFields });
      toast.success("Updated successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Failed to Update!");
    }
  };

  const handleInputChange = (e, index, field) => {
    const newInputFields = [...inputFields];
    newInputFields[index][field] = e.target.value;
    setInputFields(newInputFields);
    setFaqs((prevFaqs) => ({
      ...prevFaqs,
      FAQs: newInputFields,
    }));
  };

  const addInputField = () => {
    setInputFields([...inputFields, { question: "", response: "" }]);
  };

  const removeInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  return (
    <div className="w-full font-custom12 px-4 notepad:px-2 py-4">
      <div className="w-full notepad:px-2 px-10 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6 gap-4">
          <div className="w-full flex items-center justify-start text-2xl font-semibold mb-2">
            About Us
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="aboutUs"
              className="cursor-pointer px-3 font-semibold"
            >
              About Us
            </label>
            <textarea
              type="text"
              name="aboutUs"
              id="aboutUs"
              value={aboutUs.aboutUs}
              onChange={handleInputFieldChangeAboutUs}
              placeholder="Type here..."
              className="outline-none resize-none rounded-2xl px-4 py-4 h-56 border-[1.6px] border-gray-400 focus:border-red-400"
              required
            />
          </div>
          <div className="w-full h-20 flex items-center justify-end">
            <button
              onClick={updateAboutUs}
              className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="w-full notepad:px-2 px-10 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6 gap-4">
          <div className="w-full flex items-center justify-start text-2xl font-semibold mb-2">
            Shop Details
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="address"
              className="cursor-pointer px-3 font-semibold"
            >
              Shop Address
            </label>
            <textarea
              type="text"
              name="address"
              id="address"
              value={shopDetails.address}
              onChange={handleInputFieldChange}
              placeholder="Type here..."
              className="outline-none resize-none rounded-xl px-4 py-4 h-24 border-[1.6px] border-gray-400 focus:border-red-400"
              required
            />
          </div>
          <div className="flex items-center justify-center w-full gap-5">
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="mapsLink"
                className="cursor-pointer px-3 font-semibold"
              >
                Link - Google Maps Location (Including HTTPS in all)
              </label>
              <input
                type="text"
                name="mapsLink"
                id="mapsLink"
                value={shopDetails.mapsLink}
                onChange={handleInputFieldChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
          </div>
          <div className="flex items-center justify-center w-full gap-5 notepad:flex-col">
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="instagram"
                className="cursor-pointer px-3 font-semibold"
              >
                Instagram
              </label>
              <input
                type="text"
                name="instagram"
                id="instagram"
                value={shopDetails.instagram}
                onChange={handleInputFieldChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="facebook"
                className="cursor-pointer px-3 font-semibold"
              >
                Facebook
              </label>
              <input
                type="text"
                name="facebook"
                id="facebook"
                value={shopDetails.facebook}
                onChange={handleInputFieldChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
                required
              />
            </div>
            <div className="flex flex-col gap-1 h-20 grow notepad:w-full">
              <label
                htmlFor="pinterest"
                className="cursor-pointer px-3 font-semibold"
              >
                Pinterest
              </label>
              <input
                type="text"
                name="pinterest"
                id="pinterest"
                value={shopDetails.pinterest}
                onChange={handleInputFieldChange}
                placeholder=""
                className="outline-none rounded-md px-4 h-12 border-[1.6px] border-gray-400 focus:border-red-400"
              />
            </div>
          </div>
          <div className="w-full h-20 flex items-center justify-end">
            <button
              onClick={handleShopDetailsChange}
              className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="w-full notepad:px-2 px-10 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6 gap-4">
          <div className="w-full flex items-center justify-start text-2xl font-semibold mb-2">
            FAQs
          </div>
          <div className="w-full flex flex-col gap-3 items-center">
            {inputFields.map((input, index) => (
              <div key={index} className="flex flex-col gap-1 w-full">
                <div className="flex items-center w-full gap-5 notepad:flex-col">
                  <div className="grow notepad:w-full flex flex-col gap-1">
                    <label className="font-semibold px-2">
                      FAQ: {index + 1}
                    </label>
                    <textarea
                      type="text"
                      value={input.question}
                      onChange={(e) => handleInputChange(e, index, "question")}
                      className="w-full h-24 py-2 outline-none rounded-md px-3 resize-none border-[1.6px] border-gray-400 focus:border-red-400"
                      placeholder={`FAQ ${index + 1}`}
                    />
                  </div>
                  <div className="grow notepad:w-full flex flex-col gap-1">
                    <label className="font-semibold px-2">
                      Response: {index + 1}
                    </label>
                    <textarea
                      type="text"
                      value={input.response}
                      onChange={(e) => handleInputChange(e, index, "response")}
                      className="w-full h-24 py-2 outline-none rounded-md px-3 resize-none border-[1.6px] border-gray-400 focus:border-red-400"
                      placeholder={`Response ${index + 1}`}
                    />
                  </div>

                  {inputFields.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeInputField(index)}
                      className="text-btn-color-red-hover text-2xl font-bold bg-gray-200 px-2 py-2 rounded-full hover:bg-gray-100 active:bg-gray-200 transition-all duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={addInputField}
              className="text-btn-color-green text-lg font-semibold text-center w-full bg-gray-100 mt-4 px-4 pb-2 pt-2 rounded-full hover:bg-gray-200 active:bg-gray-300 transition-all duration-200 ease-in-out"
            >
              Add another FAQ
            </button>
          </div>
          <div className="w-full h-20 flex items-center justify-end">
            <button
              onClick={handleFAQS}
              className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {/* <form className="w-full notepad:px-2 px-10 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6 gap-4">
          <div className="w-full flex items-center justify-start text-2xl font-semibold mb-2">
            HomePage Images
          </div>
          <div className="flex flex-col items-start justify-center w-full gap-2">
            <div className="cursor-pointer px-2 text-lg font-semibold">
              Add Images
            </div>
            <div className="flex items-center justify-start notepad:justify-center gap-10 w-full flex-wrap">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className="w-[32rem] h-56 my-2 bg-gray-100 border-2 border-red-300 rounded-lg active:bg-gray-300 transition-all duration-200 hover:bg-gray-200 flex items-center justify-center relative cursor-pointer"
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={(e) => handleImageChange(e, index)}
                  />
                  {images[index] ? (
                    <img
                      src={URL.createObjectURL(images[index])}
                      alt={`Selected image ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <span className="text-2xl font-bold text-gray-500">+</span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full h-20 flex items-center justify-end">
            <button
              type="submit"
              className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
            >
              Update
            </button>
          </div>
        </div>
      </form>
      <form className="w-full notepad:px-2 px-10 py-6 flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col justify-around items-center bg-tile-color-2 rounded-2xl shadow-light-shadow overflow-hidden px-6 py-6 gap-4">
          <div className="w-full flex items-center justify-start text-2xl font-semibold mb-2">
            Cover Page Images
          </div>
          <div className="flex flex-col items-start justify-center w-full gap-2">
            <div className="cursor-pointer px-2 text-lg font-semibold">
              Add Images
            </div>
            <div className="flex items-center justify-start notepad:justify-center gap-10 w-full flex-wrap">
              {[...Array(2)].map((_, index) => (
                <div
                  key={index}
                  className="w-[32rem] h-64 my-2 bg-gray-100 border-2 border-red-300 rounded-lg active:bg-gray-300 transition-all duration-200 hover:bg-gray-200 flex items-center justify-center relative cursor-pointer"
                >
                  <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={(e) => handleImageChange(e, index)}
                  />
                  {images[index] ? (
                    <img
                      src={URL.createObjectURL(images[index])}
                      alt={`Selected image ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <span className="text-2xl font-bold text-gray-500">+</span>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full h-20 flex items-center justify-end">
            <button
              type="submit"
              className="px-8 py-2 rounded-lg hover:bg-btn-color-green-hover flex items-center justify-center h-12 transition-all duration-300 ease-in-out text-lg bg-btn-color-green active:bg-btn-color-green-active text-white"
            >
              Update
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
}
