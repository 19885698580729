import React, { useEffect, useState } from "react";
import logo from "../../Assets/images/logo/Raajhans.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isDropdownOpenMenu, setIsDropdownOpenMenu] = useState(false);
  const [activeSection, setIsActiveSection] = useState("home");
  const user = JSON.parse(localStorage.getItem("users"));
  const navigate = useNavigate();
  const location = useLocation();
  const [searchFieldValue, setSearchFieldValue] = useState("");

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("shop")) {
      setIsActiveSection("shop");
    } else if (currentPath.includes("contact")) {
      setIsActiveSection("contact");
    } else if (currentPath.includes("cart")) {
      setIsActiveSection("cart");
    } else if (currentPath.includes("orders")) {
      setIsActiveSection("orders");
    } else if (currentPath.includes("profile")) {
      setIsActiveSection("profile");
    } else if (currentPath.includes("/")) {
      setIsActiveSection("home");
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsLoggedIn(!!user);
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsDropdownOpenMenu(false);
    localStorage.clear("user");
    navigate("/authentication");
  };

  return (
    <nav className="fixed top-0 left-0 w-screen h-14 z-500">
      <div
        className={`${
          isScrolled ? "bg-nav-bg-active" : "bg-transparent"
        } flex h-full w-full transition-all duration-500 ease-in-out`}
      >
        <div
          className="h-full w-1/6 flex items-center justify-start px-5 mobile:px-3 gap-3"
          onClick={() => {
            setIsActiveSection("home");
            navigate("/");
          }}
        >
          <div className="w-8 h-8 flex items-center justify-center cursor-pointer">
            <img src={logo} className="w-full h-full" alt="RH" />
          </div>
          <div
            className="text-2xl text-white font-custom1 mobile:hidden cursor-pointer"
            onClick={() => {
              setIsActiveSection("home");
              navigate("/");
            }}
          >
            Raajhans
          </div>
        </div>
        <div className="h-full grow flex items-center font-custom4 font-normal mobile:hidden">
          <ul className="flex items-center w-full justify-around h-full">
            <div className="text-white h-full flex flex-col items-center justify-center p-3 gap-[0.15rem]">
              <div
                onClick={() => {
                  setIsActiveSection("home");
                  navigate("/");
                }}
                className="cursor-pointer"
              >
                <p>Home</p>
              </div>
              <div
                className={`${
                  activeSection === "home" ? "" : "hidden"
                } w-full h-[0.125rem] bg-white rounded-full`}
              ></div>
            </div>
            <div className="text-white h-full flex flex-col items-center justify-center p-3 gap-[0.15rem]">
              <div
                className="cursor-pointer flex items-center justify-center gap-1"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Shop
                {!isDropdownOpen && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 mt-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {isDropdownOpen && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              <div
                className={`${
                  activeSection === "shop" ? "" : "hidden"
                } w-full h-[0.125rem] bg-white rounded-full`}
              ></div>
              {isDropdownOpen && (
                <div className="absolute z-1000 w-36 top-full -mt-2 bg-white text-black shadow-md rounded-md p-2">
                  <ul>
                    <li
                      className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setIsActiveSection("shop");
                        navigate("/shop/Trending");
                      }}
                    >
                      <p>Trending</p>
                    </li>
                    <li
                      className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setIsActiveSection("shop");
                        navigate("/shop/Latest");
                      }}
                    >
                      <p>Latest</p>
                    </li>
                    <p className="pt-2 font-custom3 text-sm text-gray-700">
                      Categories
                    </p>
                    <li
                      className="cursor-pointer px-2 py-1 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setIsActiveSection("shop");
                        navigate("/shop/suit");
                      }}
                    >
                      <p>Suit</p>
                    </li>
                    <li
                      className="cursor-pointer px-2 py-1 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setIsActiveSection("shop");
                        navigate("/shop/saree");
                      }}
                    >
                      <p>Saree</p>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="text-white h-full flex flex-col items-center justify-center p-3 gap-[0.15rem]">
              <div
                onClick={() => {
                  setIsActiveSection("contact");
                  navigate("/contact");
                }}
                className="cursor-pointer"
              >
                <p>Contact</p>
              </div>
              <div
                className={`${
                  activeSection === "contact" ? "" : "hidden"
                } w-full h-[0.125rem] bg-white rounded-full`}
              ></div>
            </div>
          </ul>
        </div>
        <div className="h-full w-1/4 relative mobile:grow flex items-center justify-center font-custom12">
          <input
            type="text"
            value={searchFieldValue}
            onChange={(e) => setSearchFieldValue(e.target.value)}
            className="w-3/4 mobile:w-full h-7 mobile:h-8 px-2 text-sm rounded-l-[0.25rem] shadow-md mobile:shadow-sm focus:outline-none placeholder:font-custom9 placeholder:text-xs"
            placeholder="Search for products here..."
          />
          <div
            onClick={() => {
              navigate(`/shop/searchQuery/${searchFieldValue}`);
            }}
            className="w-7 h-7 mobile:w-8 mobile:h-8 bg-btn-color-green active:bg-btn-color-green-active hover:bg-btn-color-green-hover cursor-pointer flex items-center transition-all duration-300 ease-in-out justify-center rounded-r-[0.25rem] shadow-md mobile:shadow-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-5 text-white"
            >
              <path
                fillRule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="h-full w-1/4 mobile:w-1/6 mobile:px-1 mobile:justify-center flex items-center justify-end px-12 gap-4 font-custom10">
          {isLoggedIn && (
            <div className="flex justify-around grow items-center mobile:hidden">
              <div onClick={() => navigate("/cart")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`${
                    activeSection === "cart"
                      ? "bg-white text-btn-color-red"
                      : "text-white"
                  } size-8 transition-all duration-300 ease-in-out rounded-full flex items-center justify-center hover:bg-white active:bg-gray-200 hover:text-btn-color-red cursor-pointer px-1`}
                >
                  <path d="M2.25 2.25a.75.75 0 0 0 0 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 0 0-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 0 0 0-1.5H5.378A2.25 2.25 0 0 1 7.5 15h11.218a.75.75 0 0 0 .674-.421 60.358 60.358 0 0 0 2.96-7.228.75.75 0 0 0-.525-.965A60.864 60.864 0 0 0 5.68 4.509l-.232-.867A1.875 1.875 0 0 0 3.636 2.25H2.25ZM3.75 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM16.5 20.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
                </svg>
              </div>
              <div onClick={() => navigate("/orders")}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`${
                    activeSection === "orders"
                      ? "bg-white text-btn-color-red"
                      : "text-white"
                  } size-8 transition-all duration-300 ease-in-out rounded-full flex items-center justify-center hover:bg-white active:bg-gray-200 hover:text-btn-color-red cursor-pointer px-1`}
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {/* <div onClick={() => navigate("/profile")}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`${
                    activeSection === "profile"
                      ? "bg-white text-btn-color-red"
                      : "text-white"
                  } size-8 transition-all duration-300 ease-in-out rounded-full flex items-center justify-center hover:bg-white active:bg-gray-200 hover:text-btn-color-red cursor-pointer px-1`}
                >
                  <path
                    fillRule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div> */}
              <div onClick={handleLogout}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-8 rounded-full flex items-center transition-all duration-300 ease-in-out justify-center hover:bg-white active:bg-gray-200 hover:text-btn-color-red text-white cursor-pointer px-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          )}
          {!isLoggedIn && (
            <div className="mobile:hidden relative w-20 flex items-center justify-center h-10 rounded-md border-2 border-white cursor-pointer text-white overflow-hidden group active:border-none">
              <span className="relative z-10 transition-colors duration-500 group-hover:text-text-color-red">
                <p onClick={() => navigate("/authentication")}> Login</p>
              </span>
              <div className="absolute inset-0 bg-white transform translate-x-full transition-transform duration-500 group-hover:translate-x-0 group-active:bg-gray-400"></div>
            </div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-10 text-white active:bg-gray-300 px-1 rounded-full hidden mobile:flex"
            onClick={() => setIsDropdownOpenMenu(!isDropdownOpenMenu)}
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
              clipRule="evenodd"
            />
          </svg>
          {isDropdownOpenMenu && (
            <div className="absolute z-1000 w-36 top-full right-2 -mt-2 bg-white text-black shadow-md rounded-md p-2">
              <ul>
                <li
                  className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/");
                  }}
                >
                  <p> Home</p>
                </li>
                <p className="px-2 pt-2 text-gray-700">Shop</p>
                <li
                  className="cursor-pointer pl-4 pr-1 py-1 font-custom2 text-sm hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/shop/Trending");
                  }}
                >
                  <p> Trending</p>
                </li>
                <li
                  className="cursor-pointer pl-4 pr-1 py-1 font-custom2 text-sm hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/shop/Latest");
                  }}
                >
                  <p> Latest</p>
                </li>
                <li
                  className="cursor-pointer pl-4 pr-1 py-1 font-custom2 text-sm hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/shop/suit");
                  }}
                >
                  <p> Suit</p>
                </li>
                <li
                  className="cursor-pointer pl-4 pr-1 pt-1 font-custom2 text-sm pb-2 hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/shop/saree");
                  }}
                >
                  <p> Saree</p>
                </li>
                {isLoggedIn && (
                  <>
                    <li
                      className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpenMenu(false);
                        navigate("/cart");
                      }}
                    >
                      <p> Cart</p>
                    </li>
                    <li
                      className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpenMenu(false);
                        navigate("/orders");
                      }}
                    >
                      <p> Orders</p>
                    </li>
                    {/* <li
                      className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                      onClick={() => {
                        setIsDropdownOpenMenu(false);
                        navigate("/profile");
                      }}
                    >
                      <p> Profile</p>
                    </li> */}
                  </>
                )}
                {!isLoggedIn && (
                  <li
                    className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                    onClick={() => {
                      setIsDropdownOpenMenu(false);
                      navigate("/authentication");
                    }}
                  >
                    <p> Login</p>
                  </li>
                )}
                <li
                  className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                  onClick={() => {
                    setIsDropdownOpenMenu(false);
                    navigate("/contact");
                  }}
                >
                  <p> Contact Us</p>
                </li>
                {isLoggedIn && (
                  <li
                    className="cursor-pointer p-2 hover:bg-gray-200 rounded-lg"
                    onClick={handleLogout}
                  >
                    <p> Logout</p>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
