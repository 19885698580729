import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/HomePage";
import ProductListPage from "./Pages/ProductListPage";
import CartPage from "./Pages/CartPage";
import OrderSummayPage from "./Pages/OrderSummayPage";
import OrderedProductPage from "./Pages/OrderedProductPage";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import FAQsPage from "./Pages/FAQsPage";
import LoginSignUp from "./Components/Forms/LoginSignUp";
import AdminLogin from "./Components/Forms/AdminLogin";
import NavbarAdmin from "./Components/Navbar/NavbarAdmin";
import AdminHomePage from "./Admin/AdminHomePage";
import AdminViewOrders from "./Admin/AdminViewOrders";
import AdminViewProducts from "./Admin/AdminViewProducts";
import AdminProductDetails from "./Admin/AdminProductDetails";
import AdminAddNewProduct from "./Admin/AdminAddNewProduct";
import AdminSiteInfo from "./Admin/AdminSiteInfo";
import AdminQueries from "./Admin/AdminQueries";
import UserProfile from "./Pages/UserProfile";
import ScrollTop from "./Components/ScrollTop/ScrollTop";
import Loader from "./Components/Loader/loader";
import { Toaster } from "react-hot-toast";
import MyState from "./Context/myState";
import CustomerPage from "./CustomerPage";
import AdminPage from "./AdminPage";

function App() {
  return (
    <>
      <CustomerPage />
      <AdminPage />
    </>
  );
}

export default App;
